import { CardProvider } from 'helpers/hooks/useCustomerAssignableProducts';
import { Yup } from '@validations';

export const registerCompanyInPrismaSchema = Yup.object({
  companyType: Yup.string().required(),
  contactEmail: Yup.string().email().required(),
  fantasyName: Yup.string().required(),
});

export const customerProductSchema = Yup.object({
  id: Yup.string().required(),
  provider: Yup.mixed<CardProvider>().oneOf(['GP', 'PRISMA']).required(),
  name: Yup.string().required(),
  displayName: Yup.string().required(),
  cardType: Yup.string().required(),
});

export const addCustomerProductSchema = Yup.object({
  companyId: Yup.number().required(),
  customerProducts: Yup.array(customerProductSchema).min(1).required(),
  registerCompanyInPrisma: registerCompanyInPrismaSchema.nullable(),
});

export type AddCustomerProductSchema = Yup.InferType<typeof addCustomerProductSchema>;
export type RegisterCompanyInPrismaSchema = Yup.InferType<typeof registerCompanyInPrismaSchema>;
