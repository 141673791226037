import { CustomStyles } from '@types';

const styles: { container: CustomStyles; text: CustomStyles } = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  text: {
    fontWeight: '400',
  },
};

export default styles;
