import useSWR from 'swr';
import getCitiesByProvince, { City } from '../services/getCitiesByProvince';

/** Handle fetch all provinces from argentine and save data in cache with key /partner/states */
const useGetCitiesByProvince = (provinceId: string) => {
  const props = useSWR<City[]>(provinceId ? `/partner/state/${provinceId}/cities` : null, () =>
    getCitiesByProvince(provinceId)
  );

  return props;
};

export default useGetCitiesByProvince;
