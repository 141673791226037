const styles = {
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '190px',
  },
  emailMaxWidth: {
    maxWidth: '200px',
  },
};

export default styles;
