import { useTranslation } from 'react-i18next';
import {
  TextField,
  StandardTextFieldProps /* , InputProps as MUIInputProps */,
  InputBaseComponentProps,
} from '@mui/material';

export interface InputProps
  extends Omit<StandardTextFieldProps, 'label' | 'helperText' | 'variant'> {
  helperText?: string;
  label?: string;
  inputProps?: InputBaseComponentProps;
  variant?: 'outlined' | 'filled';
}

/**
 * @param props.
 *  @param props.label i18n key for the input label
 *  @param props.margin can be used to alter the vertical spacing of the input
 *  @param props.value input value
 *  @param props.size input size
 *  @param props.password if it is true, it shows the icon to show / hide the password and it also set security text entry.
 *  @param props.error If true, the label is displayed in an error state.
 *  @param props.onChange Callback fired when the value is changed.
 *  @param props.onBlur Callback fired when the element loses focus.
 *  @param  props.helperTextThe helper text content.
 *  @param  props.variant The variant to use
 *  @param  props.sx The system prop that allows defining system overrides as well as additional CSS styles
 *  @param  props.inputProps alter the props applied to the input element
 *  @param  props.onChange Handle input change
 * @returns input render
 */
const CustomInput = ({
  label,
  margin = 'normal',
  value,
  size = 'medium',
  error,
  onChange,
  onBlur,
  helperText,
  variant = 'outlined',
  inputProps = {},
  InputProps,
  sx = {},
  disabled = false,
  name,
  ...rest
}: InputProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      label={label ? t(label) : undefined}
      margin={margin}
      variant={variant}
      value={value}
      size={size}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText ? t(helperText) : undefined}
      sx={sx}
      inputProps={inputProps}
      InputProps={InputProps}
      fullWidth
      disabled={disabled}
      name={name}
      {...rest}
    />
  );
};
export default CustomInput;
