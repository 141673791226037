const styles = {
  button: {
    button: {
      padding: '0.5rem',
      fontSize: '14px',
      fontWeight: '400',
      color: 'gray.90',
      justifyContent: 'flex-start',
    },
  },
  containerPopover: {
    padding: '0.5rem',
  },
};

export default styles;
