import PropTypes from 'prop-types';
import { useCompanyMovements, useURLparams } from '@hooks';
import { Table } from '@molecules';
import { COLUMNS, DEFAULT_ROWS_PER_PAGE } from './constants';
import SearchBoxClientsMoves from './SearchBoxClientsMoves';

/**
 * ClientMovementsTable component renders a table with client movements data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.client - The client data.
 * @returns {JSX.Element} - The rendered component.
 */
const ClientMovementsTable = ({ client }) => {
  const { params, setURLparams } = useURLparams();

  /**
   * Function to prevent additional custom params being overwritten
   */
  function handleNewSearchParams(newValues) {
    if (params.tab) {
      newValues.tab = params.tab;
    }

    if (params.company) {
      newValues.company = params.company;
    }

    setURLparams(newValues);
  }

  const {
    filtersOpen,
    setFiltersOpen,
    isLoading,
    data,
    handlePageChange,
    handleRowsPerPageChange,
    onFiltersFormSubmit,
  } = useCompanyMovements(client?.id, {
    params,
    setURLparams: handleNewSearchParams,
  });

  return (
    <>
      <SearchBoxClientsMoves
        isLoading={isLoading}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        setURLparams={setURLparams}
        params={params}
        onSubmit={onFiltersFormSubmit}
        disabledDownload={!data?.page.length}
      />
      <Table
        count={data?.totalCount || 0}
        items={data?.page}
        columns={COLUMNS}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={params.page || 1}
        rowsPerPage={params.limit || DEFAULT_ROWS_PER_PAGE}
      />
    </>
  );
};

ClientMovementsTable.propTypes = {
  client: PropTypes.object.isRequired,
  showButtonDownload: PropTypes.bool,
};

export default ClientMovementsTable;
