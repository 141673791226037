import useSWR from 'swr';
import { useCompanyStore } from '@stores';
import useUser from '../useUser';

/** Obtains a list of segments */
const useSegments = (allowRequest = true) => {
  const { user } = useUser();
  const { selectedCompany } = useCompanyStore();

  const path = user.isInternal
    ? '/client/B2B-Segments'
    : `/partner/customer/${selectedCompany}/segment`;

  const { data, isLoading, error } = useSWR(() => (allowRequest ? path : false), {
    revalidateOnFocus: false,
  });

  const sortedSegments = data?.sort((a, b) => a.name.localeCompare(b.name));

  return { data: sortedSegments, isLoading, error };
};

export default useSegments;
