import { z } from 'zod';

/**
 * @function setToMidnight
 * @description Function to set the time of a date to midnight (00:00:00.000)
 */
const setDateToMidnight = (date: Date) => {
  const adjustedDate = new Date(date);
  adjustedDate.setHours(0, 0, 0, 0);
  return adjustedDate;
};

const searchBoxConsumptionByCommerceSchema = z
  .object({
    fromDate: z.date().optional(),
    toDate: z.date().optional(),
    product: z.string().min(1, 'COMMON:VALIDATIONS:REQUIRED_FIELD'),
  })
  .refine(
    ({ fromDate, toDate }) => {
      if (fromDate && toDate) {
        return setDateToMidnight(toDate) >= setDateToMidnight(fromDate);
      }
      return true;
    },
    {
      message: 'COMMON:VALIDATIONS:DATE_VALUE_INVALID',
      path: ['toDate'],
    }
  )
  .refine(
    ({ fromDate }) => {
      if (fromDate) {
        const today = setDateToMidnight(new Date());
        return setDateToMidnight(fromDate) <= today;
      }
      return true;
    },
    {
      message: 'COMMON:VALIDATIONS:DATE_VALUE_INVALID',
      path: ['fromDate'],
    }
  );

export type SearchBoxConsumptionByCommerceSchema = z.infer<
  typeof searchBoxConsumptionByCommerceSchema
>;

export default searchBoxConsumptionByCommerceSchema;
