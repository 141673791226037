import { z } from 'zod';

/** Map common errors on input number
 * @param issue The zod validation result for each field when parsing schema
 * @param {{ defaultError: string }} ctx - Context object with a `defaultError` property containing the default error message.
 * @return An object containing the custom error message.
 */
const mapCommonNumberErrors = (
  issue: z.ZodIssueOptionalMessage,
  { defaultError }: z.ErrorMapCtx
) => ({
  message:
    issue.code === 'invalid_type'
      ? 'COMMON:VALIDATIONS:REQUIRED_FIELD'
      : issue.message || defaultError,
});

const addCompanyAddressSchema = z.object({
  state: z.string({
    message: 'COMMON:VALIDATIONS:REQUIRED_FIELD',
  }),
  city: z
    .string({ message: 'COMMON:VALIDATIONS:REQUIRED_FIELD' })
    .min(1, 'COMMON:VALIDATIONS:REQUIRED_FIELD')
    .or(
      z.object(
        {
          label: z.string(),
          value: z.string(),
        },
        { message: 'COMMON:VALIDATIONS:REQUIRED_FIELD' }
      )
    ),
  street: z.string().min(1, 'COMMON:VALIDATIONS:REQUIRED_FIELD'),
  number: z.number({
    errorMap: mapCommonNumberErrors,
  }),
  floor: z.string().optional(),
  apartment: z.string().optional(),
  postalCode: z
    .number({
      errorMap: mapCommonNumberErrors,
    })
    .min(1000, 'COMMON:VALIDATIONS:INVALID_POSTAL_CODE')
    .max(9999, 'COMMON:VALIDATIONS:INVALID_POSTAL_CODE'),
});

export type AddCompanyAddressSchema = z.infer<typeof addCompanyAddressSchema>;

export default addCompanyAddressSchema;
