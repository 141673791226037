import { apiClient } from 'config/api';
import { userManagementFormSchemaType } from '@modules/userManagementForm/UserManagementForm.schema';

/**
 * Service for creating an user in Auth0
 * @param body - The request body.
 */
async function postCreateUser(body: userManagementFormSchemaType) {
  const url = '/auth0/user/create';
  const aux: Omit<userManagementFormSchemaType, 'customers'> & { customers: number[] } = {
    ...body,
    customers: body.customers.map((item) => Number(item)),
  };

  const response = await apiClient.post(url, aux);
  return response.data;
}
export default postCreateUser;
