import useSWR from 'swr';

export type CardProvider = 'PRISMA' | 'GP';

export interface CustomerAssignableProduct {
  id: string;
  provider: CardProvider;
  name: string;
  displayName: string;
  cardType: string;
}

/**
 * Obtains a list of Products to assign at customer
 * @param allowRequest - Indicates whether the request to fetch products should be made. If `false`, the request will not be executed
 * @param customerId - customerId to get assignable products
 *
 */
const useCustomerAssignableProducts = ({
  customerId,
  allowRequest = true,
}: {
  customerId: number;
  allowRequest?: boolean;
}) => {
  const { data, isLoading, error } = useSWR<CustomerAssignableProduct[]>(
    () => (allowRequest ? `/partner/customer/${customerId}/assignable-products` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error };
};
export default useCustomerAssignableProducts;
