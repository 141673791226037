import useSWR from 'swr';
import { useCustomerDataByCompanyId } from '../../../CompanyRepository';

/** Handle state of fetching postal address by company id */
export default function usePostalAddresByCompanyId(companyId: number | undefined) {
  const { data: customerData } = useCustomerDataByCompanyId(companyId);
  const props = useSWR(
    customerData?.id ? [`/partner/address?customerId=${customerData?.id}`] : null,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  return props;
}
