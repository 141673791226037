import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { apiClient } from 'config/api';
import { ProductName } from '@globalConstants';

export interface Product {
  account_number: string;
  cvu: string | null;
  alias: string | null;
  cards: PrepaidCard[];
  product: ProductName | null;
  categories: string[];
  balance_available: number;
  balance_total: number;
  balance_frozen: number;
  created_at?: Date;
  modified_at?: Date;
  is_business?: boolean;
  status: string;
  allow_dispersion: boolean;
  account_id: string;
  allow_recovery?: boolean;
  customer: Customer;
}

export interface Customer {
  id: string;
  customer_group: CustomerGroup;
  legal_identification: string;
  legal_name: string;
}

export interface CustomerGroup {
  id: string;
  name: string;
}

export interface PrepaidCard {
  provider: string;
  card_type: string;
  status: string;
  ending: string;
  expiration: string;
  kind: string;
  physical_card_request_date?: string;
}

/** Serialize json to product */
function serializeProduct(product: Product) {
  return {
    ...product,
    created_at: product.created_at ? new Date(product.created_at) : undefined,
    modified_at: product.modified_at ? new Date(product.modified_at) : undefined,
  };
}

/** Fetch all user products with their account by client id */
async function fetchProductsBy(clientId: string): Promise<Product[]> {
  const response = await apiClient.get(`/client/productsV2/${clientId}`);
  return response.data.map(serializeProduct);
}

/**
 * Returns the state of fetching all user products along with their account details by client ID.
 *
 * This hook fetches products using the client ID and optionally selects a product based on the account number.
 *
 * @param {string} clientId - The ID of the client to fetch products for.
 * @param {string} [accountNumber] - Optional account number to automatically select a product.
 * @returns {Object} Hook state including the fetched products, selected product, error state, loading flags, and a mutate function to refresh data.
 */
export const useProductsBy = (clientId: string, accountNumber?: string) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    clientId ? `/client/productsV2/${clientId}` : null,
    () => fetchProductsBy(clientId),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  /**
   * Handles product selection when data changes.
   */
  useEffect(() => {
    if (data && data.length) {
      setSelectedProduct((prevSelelectedProduct) =>
        data.find((item) => item.account_number === prevSelelectedProduct?.account_number)
      );
    }
  }, [data]);

  /**
   * Selects the initial product when no product is selected.
   */
  useEffect(() => {
    if (data && data.length && !selectedProduct) {
      if (accountNumber) {
        setSelectedProduct(data.find((item) => item.account_number === accountNumber));
      } else {
        setSelectedProduct(data[0]);
      }
    }
  }, [accountNumber, data, selectedProduct]);

  return { data, error, isLoading, isValidating, mutate, selectedProduct, setSelectedProduct };
};
