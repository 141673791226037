import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { AutocompleteComplex, Button, Input, Select, Snackbar } from '@atoms';
import { useSnackbar } from '@hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import addCompanyAddressSchema, { AddCompanyAddressSchema } from './AddCompanyPostalAddress.schema';
import getCustomerDataByCompanyId from '../../data/CompanyRepository/services/getCustomerDataByCompanyId';
import {
  useGetCitiesByProvince,
  useProvinceFromArgentine,
  Province,
  City,
} from '../../data/PlacesRepository';
import postPostalAddress from '../../data/PlacesRepository/address/services/postPostalAddress';

interface AddCompanyPostalAddressProps {
  companyId: number;
  navigateBack: () => void;
  navigateToDetails: (result: 'success' | 'failed') => void;
}

/** Add company postal address view */
const AddCompanyPostalAddress: FC<AddCompanyPostalAddressProps> = ({
  companyId,
  navigateBack,
  navigateToDetails,
}) => {
  const { t } = useTranslation();
  const { showSnackbar, snackbarProps } = useSnackbar();
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<AddCompanyAddressSchema>({
    resolver: zodResolver(addCompanyAddressSchema),
  });

  const state = watch('state');

  const { data: provinces, isLoading: isLoadingProvinces } = useProvinceFromArgentine();
  const { data: cities } = useGetCitiesByProvince(state);

  /**
   * Handles the submission of the add company postal address form when it is valid.
   *
   * This function will be called with the validated form data once the form has been successfully submitted.
   *
   * @param data - The validated form data.
   */
  const onSubmit = async (data: AddCompanyAddressSchema) => {
    const city =
      typeof data.city === 'string'
        ? { name: data.city }
        : {
            id: data.city.value,
          };

    try {
      const customerData = await getCustomerDataByCompanyId(companyId);
      const customerId = customerData.id;

      await postPostalAddress({
        city,
        customerId,
        number: String(data.number),
        apartment: data.apartment,
        floor: data.floor,
        postalCode: String(data.postalCode),
        street: data.street,
        stateId: data.state,
      });
      reset();
      navigateToDetails('success');
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        showSnackbar(error.message, 'error');
      }
    }
  };

  /**
   * Handles key down events for numeric input fields.
   *
   * Allows:
   * - Number keys (0-9)
   * - Control keys: Backspace, Tab, Arrow keys, Delete, Home, End
   *
   * Handles paste operations:
   * - Detects "Ctrl+V" (Windows/Linux) or "Cmd+V" (macOS)
   * - Prevents pasting if the content is not numeric.
   *
   * Blocks any other disallowed keys.
   */
  const handleKeyDownNumeric = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Home', 'End'];
    const { key, ctrlKey, metaKey } = event;
    if (!/[0-9]/.test(key) && !allowedKeys.includes(key)) {
      if ((ctrlKey || metaKey) && (key === 'v' || key === 'c' || key === 'x')) {
        navigator.clipboard.readText().then((pastedText) => {
          if (!/^\d+$/.test(pastedText)) {
            event.preventDefault();
          }
        });
      } else {
        event.preventDefault();
      }
    }
  };

  /**
   * Handles the maximum length of input fields.
   * @param maxDigits - The maximum number of digits allowed in the input
   * @returns The handleMaxLength function
   */
  const handleMaxLength = (maxDigits: number) => {
    return ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (target.value.length > maxDigits) {
        target.value = target.value.slice(0, maxDigits); // Limita a 4 dígitos
      }
    };
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader
            title={t('CLIENTS:DETAIL:POSTAL_ADDRESS:TITLE')}
            titleTypographyProps={{ typography: 'h2' }}
          />
          <CardContent sx={{ paddingTop: 0 }}>
            <Grid
              container
              rowSpacing={{ xs: 2, sm: 4, md: 4 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={6}>
                <Select
                  {...register('state')}
                  custmStyle={{ backgrund: 'red' }}
                  customStyles={{ background: 'red', order: 1 }}
                  error={Boolean(errors.state?.message)}
                  helperText={errors.state?.message ? t(errors.state.message) : undefined}
                  isLoading={isLoadingProvinces}
                  items={provinces ?? []}
                  label={t('CLIENTS:DETAIL:POSTAL_ADDRESS:STATE')}
                  parseOption={(item: Province) => ({
                    label: item.name,
                    value: item.id,
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller<AddCompanyAddressSchema>
                  control={control}
                  name='city'
                  render={({ field }) => (
                    <AutocompleteComplex<City, false, undefined, true>
                      freeSolo
                      inputProps={{
                        inputRef: field.ref,
                        onBlur: field.onBlur,
                      }}
                      items={cities ?? []}
                      value={field.value as string}
                      label={t('CLIENTS:DETAIL:POSTAL_ADDRESS:CITY')}
                      error={Boolean(errors.city?.message)}
                      helperText={errors.city?.message ? t(errors.city.message) : undefined}
                      parseOption={(item) => ({
                        label: item.name,
                        value: item.id,
                      })}
                      onChange={({ target: { value } }) => {
                        return field.onChange(value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  error={Boolean(errors.street?.message)}
                  helperText={errors.street?.message ? t(errors.street.message) : undefined}
                  inputProps={register('street')}
                  label={t('CLIENTS:DETAIL:POSTAL_ADDRESS:STREET')}
                  onInput={handleMaxLength(100)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  error={Boolean(errors.number?.message)}
                  helperText={errors.number?.message ? t(errors.number.message) : undefined}
                  inputProps={register('number', { valueAsNumber: true, max: 4 })}
                  label={t('CLIENTS:DETAIL:POSTAL_ADDRESS:NUMBER')}
                  name='number'
                  type='number'
                  onKeyDown={handleKeyDownNumeric}
                  onInput={handleMaxLength(10)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  error={Boolean(errors.floor?.message)}
                  helperText={errors.floor?.message ? t(errors.floor.message) : undefined}
                  inputProps={register('floor')}
                  label={t('CLIENTS:DETAIL:POSTAL_ADDRESS:FLOOR')}
                  onInput={handleMaxLength(10)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  error={Boolean(errors.apartment?.message)}
                  helperText={errors.apartment?.message ? t(errors.apartment.message) : undefined}
                  inputProps={register('apartment')}
                  label={t('CLIENTS:DETAIL:POSTAL_ADDRESS:APARMENT')}
                  onInput={handleMaxLength(10)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  error={Boolean(errors.postalCode?.message)}
                  helperText={errors.postalCode?.message ? t(errors.postalCode.message) : undefined}
                  inputProps={register('postalCode', { valueAsNumber: true })}
                  label={t('CLIENTS:DETAIL:POSTAL_ADDRESS:POSTAL_CODE')}
                  name='postalCode'
                  type='number'
                  onKeyDown={handleKeyDownNumeric}
                  onInput={handleMaxLength(20)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Stack direction='row' spacing={2} justifyContent='flex-end' mt={2}>
          <Button
            variant='outlined'
            text='Cancelar'
            disabled={isSubmitting}
            onClick={navigateBack}
          />
          <Button variant='contained' text='Agregar' type='submit' isLoading={isSubmitting} />
        </Stack>
      </form>
      <Snackbar {...snackbarProps} />
    </>
  );
};

export default AddCompanyPostalAddress;
