import PropTypes from 'prop-types';
/**
 * @param {object} props
 * @param {string} props.color logo color
 * @returns {JSX.Element} the Prisma logo component
 */
const PrismaLogo = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} fill='none'>
    <path
      stroke={color}
      strokeLinejoin='round'
      d='m10.79 1 10.204 6.122-3.673 2.041M10.79 1 .993 7.122M10.79 1v4.082m6.53 4.081v7.755m0-7.755-6.53-4.081m6.53 11.836L10.79 21l-6.53-4.082m13.06 0-6.53-4.081m-6.53 4.081L.993 18.551V7.122m3.265 9.796 6.531-4.081m-6.53 4.081V9.163M.993 7.123l3.265 2.04m6.531 3.674V5.082M4.26 9.163l6.53-4.081'
    />
  </svg>
);

PrismaLogo.propTypes = {
  color: PropTypes.string,
};
export default PrismaLogo;
