import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';
import NotFoundPostalAddressException from '../exceptions/NotFoundPostalAddressException';

export interface CompanyPostalAddress {
  customerId: number;
  street: string;
  number: number;
  floor?: number;
  apartment?: string;
  postalCode: string;
  cityName: string;
  stateName: string;
}

/** Fetch customer data by company id then fetch postal address by customer id  */
const getPostalAddressByCustomerId = async (customerId: number): Promise<CompanyPostalAddress> => {
  try {
    const { data: companyPostalAddress } = await apiClient.get(
      `/partner/address?customerId=${customerId}`
    );
    return companyPostalAddress;
  } catch (error) {
    if (isAxiosError(error) && error.response?.data.code === 'not_found') {
      throw new NotFoundPostalAddressException();
    }
    throw new Error('COMMON:ERRORS:DEFAULT_ERROR');
  }
};

export default getPostalAddressByCustomerId;
