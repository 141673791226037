import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

export interface Province {
  id: string;
  name: string;
}

/** Fetch all provinces from argentine from /partner/states */
async function getProvincesFromArgentine(): Promise<Province[]> {
  try {
    const response = await apiClient.get('/partner/states');
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.message);
    }
    throw new Error('Unexpected error');
  }
}

export default getProvincesFromArgentine;
