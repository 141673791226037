import useSWR from 'swr';

/**
 * SWR hook to fetch consumption by category
 */
const useConsumptionByCategory = (companyId: string, params: Record<string, string>) => {
  const query = new URLSearchParams({ ...params, companyId }).toString();
  const { data, isLoading, error, mutate } = useSWR(
    () => (params?.product ? `/client/consumption-by-category?${query}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error, mutate };
};

export default useConsumptionByCategory;
