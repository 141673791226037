import { usePartnerCustomers } from '@hooks';

export const FIELDS = [
  { name: 'firstName', label: 'COMMON:FIRST_NAME', type: 'input', getOptions: () => {} },
  { name: 'lastName', label: 'COMMON:LAST_NAME', type: 'input', getOptions: () => {} },
  { name: 'email', label: 'COMMON:EMAIL', type: 'input', getOptions: () => {} },
  {
    name: 'customers',
    label: 'COMMON:COMPANY',
    getOptions: usePartnerCustomers,
    type: 'autocomplete',
  },
] as const;
