import { useTranslation } from 'react-i18next';
import { defaultProductColor } from 'theme/colors';
import { Box, Stack, Typography } from '@mui/material';
import { VisaLogo, PrismaLogo } from 'assets/svg';
import { PRODUCTS_COLORS, ProductName } from '@globalConstants';
import { Chip } from '@molecules';
import styles from './styles';
import { PrepaidCard as IPrepaidCard } from '../useProducts';

interface PrepaidCardProps {
  card: IPrepaidCard;
  product: ProductName | null;
}

/** Preapaid card component */
const PrepaidCard = ({ card, product }: PrepaidCardProps) => {
  const { t } = useTranslation();
  const isProvidedByPrisma = card.provider === 'PRISMA';
  const productColor = product ? PRODUCTS_COLORS[product] : defaultProductColor;
  return (
    <Box width={328} sx={styles.container(productColor)}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Chip sx={styles.chip(productColor)} variant='outlined' label={card.status} />
        <Stack direction='row' alignItems='baseline' gap={1}>
          <Box sx={[isProvidedByPrisma ? styles.textAlignCenter : styles.alignSelfEnd]}>
            {isProvidedByPrisma && <PrismaLogo color={productColor.complementary} />}
            <Typography sx={styles.caption(productColor)}>{card.provider}</Typography>
          </Box>
          <Box sx={{ margin: 0, padding: 0, textAlign: 'end' }}>
            <VisaLogo color={productColor.complementary} />
            <Typography sx={styles.caption(productColor)}>{card.kind}</Typography>
          </Box>
        </Stack>
      </Stack>
      {card.ending && (
        <Stack alignItems='flex-end'>
          <Typography variant='h1' color='primary' sx={styles.text(productColor)}>
            •••• {card.ending}
          </Typography>
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
        <Box>
          <Typography
            component='p'
            variant='caption'
            color='primary'
            sx={styles.text(productColor)}
          >
            {t('COMMON:TYPE')}
          </Typography>
          <Chip
            variant='filled'
            label={t(`EMPLOYEES:DETAIL:CARD_TYPE:${card.card_type}`)}
            sx={styles.chip(productColor)}
          />
        </Box>
        {card.expiration && (
          <Box sx={styles.expirationDate}>
            <Typography component='p' variant='caption' sx={styles.text(productColor)}>
              {t('COMMON:EXPIRATION_DATE')}
            </Typography>
            <Typography variant='h4' sx={styles.text(productColor)}>
              {card.expiration}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default PrepaidCard;
