import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { subYears } from 'date-fns';
import PropTypes from 'prop-types';
import { Box, Grid, List } from '@mui/material';
import { Input, Snackbar, ConfirmModal, StrongModalText, DateInput } from '@atoms';
import {
  useItemListToForm,
  useSegments,
  useUser,
  useFilterInternalFeatures,
  useURLparams,
} from '@hooks';
import { EditActionButtons, SelectFetch as Select } from '@molecules';
import { camelizeString, dateToIsoString, formatDate, normalizePhoneNumber } from '@utils';
import { apiClient } from '@config/api';
import { ITEMS, REGISTER_STATUS, SEGMENT_EDITOR_ROLES, ITEMS_GRID } from './constants';
import styles from './styles';

/**
 * Component for the client details tab
 * @param {object} props
 *  @param {object} props.client The object containing the client data
 *  @param {function} props.mutate The object containing the client data
 */
const DetailsTab = ({ client, mutate, isEditing, setEditing }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const [editFailed, setEditFailed] = useState(false);
  const [failSeverity, setFailSeverity] = useState('error');

  const [failMessage, setFailMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [editSuccess, setEditSuccess] = useState(false);

  const [showSendMailModal, setShowSendMailModal] = useState(false);

  const { user } = useUser();

  const { t } = useTranslation();

  const formRef = useRef(null);

  const { formProps, changedValues } = useItemListToForm(ITEMS, client, onSubmit, t);

  const canEditSegment = user.roles.some((role) => SEGMENT_EDITOR_ROLES.includes(role));

  const { data: segments } = useSegments(canEditSegment);

  const { params, setURLparams } = useURLparams();

  const { values, errors, handleChange } = formProps;

  const inputComponents = {
    Input,
    Select,
    DateInput,
  };

  const FILTER_ITEMS = ['COMMON:PHONE_NUMBER', 'COMMON:GENDER', 'COMMON:BIRTH_DATE'];

  const items = useFilterInternalFeatures(ITEMS);

  const registeredFilterItems =
    client.registrationStatus === REGISTER_STATUS.REGISTERED
      ? items
      : items.filter((item) => !FILTER_ITEMS.includes(item.label));

  /**
   * Submit the form
   * @param {object} values Values to submit
   * @param {object} formHelpers Form helpers
   */
  async function onSubmit(values, { setErrors: setErroredFields }) {
    setFailSeverity('error');
    setSubmitting(true);
    setFailMessage(null);
    setErroredFields({});

    let ok = true;

    const segment =
      values.segment && segments.filter((segment) => segment.number === values.segment)[0];

    const hasChangedSegment = segment && segment.name !== client.segment;
    const hasChangedEmail = values.email !== client.email;
    const hasChangedEmailOrSegment = hasChangedEmail | hasChangedSegment;
    const hasChangedMobileNumber =
      client.registrationStatus === REGISTER_STATUS.REGISTERED &&
      values.mobileNumber !== normalizePhoneNumber(client?.mobileNumber);
    const hasChangedName = values.firstName !== client.firstName;
    const hasChangedDNI = !!values.dni && values.dni !== client.dni;
    const hasChangedCUIT = values.cuit !== client.cuit;
    const hasChangedLastName = values.lastName !== client.lastName;
    const hasChangedBirthDate =
      formatDate(values.birthDate, { dateOnly: true }) !==
      formatDate(client.birthDate?.replace('Z', ''), { dateOnly: true });

    const hasChangedPersonalData =
      hasChangedMobileNumber ||
      hasChangedName ||
      hasChangedLastName ||
      hasChangedDNI ||
      hasChangedCUIT ||
      hasChangedBirthDate;
    const sendEmailUnregisteredUser =
      hasChangedEmail || hasChangedName || hasChangedLastName || hasChangedDNI || hasChangedCUIT;

    const emailSegmentResponse = { data: null, error: null };

    if (hasChangedEmailOrSegment) {
      const body = {};

      if (hasChangedSegment) {
        body.segment = {
          type: segment.number,
          entity_id: segment.name,
        };
      }
      if (hasChangedEmail) {
        body.email = values.email;
      }

      try {
        const { data } = await apiClient.patch(
          `/client/segment-or-email/${client.documentNumber}`,
          body
        );
        emailSegmentResponse.data = data;
      } catch (error) {
        if (error) {
          emailSegmentResponse.error = error.response || error;
        }
      }

      if (emailSegmentResponse.data) {
        const { type } = emailSegmentResponse.data.lmb;
        if (emailSegmentResponse.data?.nwuser) {
          const { email, entity_id: segment } = emailSegmentResponse.data.nwuser;
          mutate({ ...client, email, segment, type }, { revalidate: false });
        } else {
          const { email } = emailSegmentResponse.data.lmb;
          mutate({ ...client, email, type }, { revalidate: false });
        }
      } else {
        ok = false;
        const { error } = emailSegmentResponse;
        let errorMessage;

        if (error) {
          if (error?.status === 409) {
            errorMessage = t('EMPLOYEES:DETAIL:ERRORS:EMAIL_ALREADY_EXISTS');
            setErroredFields({ email: true });
          }
          console.error(error);
        }
        setFailMessage(errorMessage, {
          fields: t('COMMON:EMAIL'),
        });
        setEditFailed(true);
      }
    }

    if (ok && hasChangedPersonalData) {
      const body = { userId: client.walletId };

      if (hasChangedMobileNumber) {
        body.mobileNumber = values.mobileNumber;
      }

      if (hasChangedName) {
        body.firstName = camelizeString(values.firstName);
      }

      if (hasChangedLastName) {
        body.lastName = camelizeString(values.lastName);
      }

      if (hasChangedDNI) {
        body.dni = values.dni;
      }

      if (hasChangedCUIT) {
        body.cuit = values.cuit;
      }
      if (hasChangedBirthDate) {
        body.birthdate = dateToIsoString(values.birthDate);
      }

      try {
        const { data } = await apiClient.patch(`/client/${client.cuit}`, body);
        if (data.success) {
          mutate(
            {
              ...client,
              ...body,
              ...(hasChangedBirthDate && {
                birthDate: `${dateToIsoString(values.birthDate)}T00:00:00.000Z`,
              }),
            },
            { revalidate: false }
          );
        }
      } catch (error) {
        setEditFailed(true);
        ok = false;
        const ResponseError = error?.response?.data?.errors[0];
        if (ResponseError?.message?.phone_number) {
          setErroredFields({ mobileNumber: true });
          setFailMessage(
            t('EMPLOYEES:DETAIL:ERRORS:PHONE_ALREADY_EXISTS', {
              fields: t('COMMON:PHONE_NUMBER'),
            })
          );
        } else if (ResponseError?.code === '5003')
          setFailMessage('EMPLOYEES:DETAIL:ERRORS:PROVIDER_ERROR');
        else setFailMessage('EMPLOYEES:DETAIL:ERRORS:EDIT_ERROR');
      }
    }

    if (
      ok &&
      client.registrationStatus === REGISTER_STATUS.PRECOMPLETED &&
      sendEmailUnregisteredUser
    ) {
      const body = {};

      if (hasChangedEmail) {
        body.email = values.email;
      }

      if (hasChangedName) {
        body.firstName = camelizeString(values.firstName);
      }

      if (hasChangedLastName) {
        body.lastName = camelizeString(values.lastName);
      }

      if (hasChangedDNI) {
        body.dni = values.dni;
      }

      if (hasChangedCUIT) {
        body.cuit = values.cuit;
      }

      try {
        await apiClient.patch(`/client/prospect/${client.walletId}`, body);
      } catch (error) {
        ok = false;
        setFailMessage(t('EMPLOYEES:DETAIL:ERRORS:FAIL_SENDIG_MAIL'));
        setEditFailed(true);
      }
    }

    setSubmitting(false);

    if (ok) {
      setEditSuccess(true);
      setSuccessMessage('EMPLOYEES:DETAIL:SUCCESS_EDIT');
      setEditing(false);
      setURLparams({ ...params, tab: 'detalle' });
    }
  }
  /**
   * Re-send register email
   */
  async function resendEmail() {
    try {
      await apiClient.post(`/client/prospects/send-notification/${client.walletId}`);
      setSuccessMessage('EMPLOYEES:DETAIL:SUCCESS_RESEND_EMAIL');
      setEditSuccess(true);
    } catch (error) {
      setFailMessage(t('EMPLOYEES:DETAIL:ERRORS:FAIL_RESEND_EMAIL'));
      setEditFailed(true);
    } finally {
      setShowSendMailModal(false);
    }
  }
  return (
    <>
      <ConfirmModal
        modalVisible={showSendMailModal}
        setModalVisible={setShowSendMailModal}
        description={
          <Trans
            i18nKey='EMPLOYEES:DETAIL:RESEND_EMAIL_DESCRIPTION'
            values={{ email: client?.email }}
          >
            Se va a reenviar un email a
            <u>
              <StrongModalText>{client?.email}</StrongModalText>
            </u>
          </Trans>
        }
        title={t('EMPLOYEES:DETAIL:RESEND_EMAIL')}
        handleSubmit={resendEmail}
      />
      <Box>
        <Box sx={styles.content}>
          <Box sx={styles.itemsBox} component={isEditing ? 'form' : 'div'} ref={formRef}>
            <List>
              <Grid container spacing={2}>
                {ITEMS_GRID.map((row, index) => (
                  <Grid container item key={`row-${index}`} spacing={2}>
                    {row.map((item) => {
                      const infoItem = registeredFilterItems.find((i) => i.inputName === item);
                      if (!infoItem) return;
                      const {
                        label,
                        inputType,
                        inputName,
                        key,
                        getOptions,
                        parseOption,
                        multiple,
                        getText,
                        editorRoles,
                      } = infoItem;
                      const isSelect = inputType === 'select' && isEditing;
                      const isDate = inputType === 'date';
                      const renderDate = isDate && isEditing;
                      const isInput = !isSelect && !renderDate;
                      const Component =
                        inputComponents[isSelect ? 'Select' : renderDate ? 'DateInput' : 'Input'];
                      const isAllowedToEdit = user.roles.some((role) =>
                        editorRoles?.includes(role)
                      );
                      return (
                        <Grid item xs={12} sm={6} key={key}>
                          <Component
                            onChange={(e) => handleChange(e, inputName)}
                            label={label}
                            name={inputName}
                            value={values[inputName]}
                            error={errors[inputName]}
                            helperText={
                              typeof errors[inputName] === 'string' ? errors[inputName] : null
                            }
                            {...(isSelect && {
                              getOptions,
                              parseOption,
                              multiple,
                            })}
                            {...(renderDate && {
                              minDate: subYears(new Date(), 100),
                              maxDate: subYears(new Date(), 16),
                            })}
                            {...(isInput && {
                              inputProps: {
                                readOnly: !isEditing || !isAllowedToEdit,
                              },
                              disabled: isEditing && !isAllowedToEdit,
                              sx: {
                                '& .MuiInputLabel-root': styles.editInput(
                                  isEditing && !isAllowedToEdit
                                ),
                              },
                            })}
                            {...(isDate && !isEditing && { value: getText(client) })}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ))}
              </Grid>
            </List>
          </Box>
        </Box>
        <Box sx={styles.buttonsContainer}>
          {isEditing && (
            <EditActionButtons
              formRef={formRef}
              isLoading={isSubmitting}
              isEditingState={{ isEditing, setEditing }}
              formProps={formProps}
              confirmationModal={{
                title: t('EMPLOYEES:DETAIL:CONFIRM_MODAL_TITLE'),
                description: t('EMPLOYEES:DETAIL:CONFIRM_MODAL_DESCRIPTION', {
                  changedValues,
                }),
              }}
            />
          )}
        </Box>
        <Snackbar
          open={editFailed}
          message={t(failMessage)}
          severity={failSeverity}
          handleClose={() => setEditFailed(false)}
        />
        <Snackbar
          open={editSuccess}
          message={t(successMessage)}
          severity='success'
          handleClose={() => setEditSuccess(false)}
        />
      </Box>
    </>
  );
};
DetailsTab.propTypes = {
  client: PropTypes.object,
  mutate: PropTypes.func,
  setEditing: PropTypes.func,
  isEditing: PropTypes.bool,
  handleGoBack: PropTypes.func,
};

export default DetailsTab;
