import useSelectedCompanyCategories from './useSelectedCompanyCategories';

/**
 * @function useShowMenuByCategories
 * @description This function is used to show the menu based on the categories is generic or more than one category.
 * @return {boolean} - Returns a boolean value indicating whether the menu should be shown or not.
 * @example
 * const showMenu = useShowMenuByCategories();
 */
const useShowMenuByCategories = (): boolean => {
  const { data: categories } = useSelectedCompanyCategories();

  const isGeneric = categories.includes('GENERIC');

  return isGeneric;
};

export default useShowMenuByCategories;
