import { subMonths } from 'date-fns';

/**
 * @function getDatesFromTheLastMonth
 * @description Get the from date and to date from the last month
 */
const getDatesFromTheLastMonth = () => {
  const now = new Date();
  const monthAgo = subMonths(now, 1);

  return { fromDate: monthAgo, toDate: now };
};

export default getDatesFromTheLastMonth;
