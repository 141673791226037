import PropTypes from 'prop-types';
import { Autocomplete } from '@atoms';
import { useUserSegments } from '@hooks';
import { useSegmentStore } from '@stores';

/**
 * Re-usable Organism component for selecting a user segment.
 * @param {object} props
 * @param {boolean} props.disabled disabled
 * @param {string} props.placeholder placeholder
 * @returns {React.Component} Segment selector
 */
const SelectSegment = ({ disabled, placeholder }) => {
  const { data, isLoading } = useUserSegments();
  const { selectedSegment, setSelectedSegment } = useSegmentStore();

  /**
   * Handles the global selection of segment
   * @param {object} value
   */
  const onChange = (option) => {
    const { value } = option;
    if (value) {
      setSelectedSegment(value);
    }
  };

  return (
    <Autocomplete
      isLoading={isLoading}
      disabled={disabled}
      onChange={(_e, value) => onChange(value)}
      items={data}
      value={selectedSegment}
      parseOption={(item) => ({ label: item.name, value: item.number })}
      isOptionEqualToValue={(option, value) => option.value === value}
      getOptionLabel={(value) => {
        return typeof value === 'object'
          ? value.label ?? ''
          : data?.find((item) => item.number === value)?.name || '';
      }}
      placeholder={placeholder ?? 'COMMON:SELECT_SEGMENT'}
    />
  );
};

SelectSegment.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default SelectSegment;
