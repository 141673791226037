import i18n from '@i18n';
import { parseAmount, parseNumber } from '@utils';

type ConsumptionFieldKey = 'fromDate' | 'toDate' | 'product';

export interface FilterFields {
  name: ConsumptionFieldKey;
  label: string;
  getOptions?: () => { data: Promise<Record<string, string>>; isLoading: boolean };
  parseOption?: (value: string) => Record<string, string>;
  type: 'datePicker' | 'selectFetch' | 'input';
}

export const DATE_PICKER_PROPS = {
  size: 'medium',
  dateOnly: true,
  readOnly: false,
  disablePast: false,
  calendarViews: ['year', 'month', 'day'],
  fullWidth: true,
};

export const DEFAULT_ROWS_PER_PAGE = 25;

/** This function is used to set the footer of the table */
export const ITEMS_FOOTER = ({
  trxCount,
  sumTotalAmount,
  balanceAverageTx,
}: Record<string, string | number>) => [
  i18n.t('COMMON:TOTAL'),
  '',
  '',
  parseNumber(Number(trxCount)),
  parseAmount(Number(sumTotalAmount)),
  parseAmount(Number(balanceAverageTx)),
];

export const COLUMNS = [
  {
    title: 'COMMON:COMMERCE',
    content: ({ commerce }: Record<string, string>) => commerce,
  },
  {
    title: 'COMMON:CATEGORY',
    content: ({ category }: Record<string, string>) => category,
  },
  {
    title: 'CONSUMPTION:CONSUMPTION_RATE',
    content: ({ consumptionRate }: Record<string, string>) => consumptionRate,
  },
  {
    title: 'CONSUMPTION:TRX_COUNT',
    content: ({ trxCount }: Record<string, number>) => parseNumber(trxCount),
  },
  {
    title: 'CONSUMPTION:CATEGORY_AMOUNT',
    content: ({ commerceAmount }: Record<string, string>) => parseAmount(Number(commerceAmount)),
  },

  {
    title: 'CONSUMPTION:BALANCE_AVERAGE_TX',
    content: ({ balanceAverageTx }: Record<string, number>) => parseAmount(balanceAverageTx),
  },
];
