import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { boolean } from 'yup';
import {
  Alert,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Chip,
  useMediaQuery,
} from '@mui/material';
import { IconSearch, IconFileExport } from '@tabler/icons-react';
import { Button, Input, Select, IconButton } from '@atoms';
import {
  useCategoriesByCompany,
  useExportAllUserAccountsFiltered,
  useFilterInternalFeatures,
  useProductsByCompany,
  useUser,
  useUserCompanies,
} from '@hooks';
import { useCompanyStore } from '@stores';
import { clearObject } from '@utils';
import {
  CATEGORIES_PARSE_OPTIONS,
  FIELDS,
  PRODUCT_PARSE_OPTIONS,
  cuilValidationSchema,
  validationSchema,
} from './constants';
import styles from './styles';

/**
 * Renders a modal with the inputs fields and search clients
 * @param {object} props
 * @param  {boolean} props.isLoading flag indicating whether the request is loading
 * @param  {object} props.params the current URL params
 * @param  {boolean} props.disabledExportAll flag indicating whether the export all button should be disabled
 * @param  {() => void | () => Promise<void>} props.onFiltersFormSubmit function to set clients data
 */
const SearchBoxClient = ({ isLoading, params, disabledExportAll, onFiltersFormSubmit }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const fields = useFilterInternalFeatures(FIELDS);
  const { data: userCompanies } = useUserCompanies();
  const { selectedCompany } = useCompanyStore();
  const [isMultipleSearching, setIsMultipleSearching] = useState(
    Boolean(params?.cuit?.includes(','))
  );
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const searchClientFormik = useFormik({
    initialValues: {
      cuit: getCuitInitialValue(),
      dni: params?.dni || '',
      firstName: params?.firstName || '',
      lastName: params?.lastName || '',
      walletId: params?.walletId || '',
      email: params?.email || '',
      product: params?.product || '',
      categories: params?.categories?.split(',') || [],
      companyId: params?.companyId || '',
    },
    validationSchema: validationSchema({ user }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: onFiltersFormSubmit,
  });
  /**
   * get cuit initial value
   * @returns Array of cuit or string of one cuit
   */
  function getCuitInitialValue() {
    return params?.cuit?.includes(',') ? params.cuit.split(',') : params.cuit ?? '';
  }

  const {
    handleChange: formikHandleChange,
    handleSubmit,
    handleBlur,
    isValid,
    errors,
    values,
    setValues,
  } = searchClientFormik;

  const cuilIsArray = values?.cuit instanceof Array;
  const companyId = user.isInternal ? values.companyId : selectedCompany;
  const productHooksProps = { companyId, allowRequest: Boolean(companyId) };

  const {
    data: categoriesData,
    isLoading: isCategoriesLoading,
    error: categoriesError,
  } = useCategoriesByCompany(productHooksProps);

  const {
    data: productsData,
    isLoading: isProductsLoading,
    error: productsError,
  } = useProductsByCompany(productHooksProps);

  const canSelectCompany = user.isInternal;

  /**
   * Clears the filters
   */
  function handleClearFilters() {
    const newValues = clearObject(values);
    setValues({ ...newValues, cuit: '' });
  }

  /**
   * Handles the form change
   * Trims whitespaces at the beginning and end if the event is a clipboard paste
   */
  function handleChange(name) {
    const handler = formikHandleChange(name);
    return (event) => {
      if (event.type === 'paste') {
        event.preventDefault();
        handler(event.clipboardData.getData('text/plain').trim());
      } else {
        handler(event.target.value);
      }
    };
  }
  /**
   * Cuit multiple search handle key down event
   * @param event Handle key down event
   * @param _props Formik props
   */
  const cuilHandleKeyDown = (event, _props) => {
    if (_props.errors['cuit'] || event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    const newCUIL = event.target?.value?.trim();
    if (newCUIL !== '') {
      searchClientFormik.setFieldValue('cuit', [...(cuilIsArray ? values.cuit : []), newCUIL]);
      _props.setValues({ cuit: '' });
    }
  };
  const disableButton = isLoading || !isValid;
  const maxCuilMultipleSearch = cuilIsArray && values?.cuit?.length >= 10;

  const hasSelectedACompany = Boolean(values.companyId) || Boolean(selectedCompany);

  const { isLoading: isLoadingExportAll, exportAllUserAccountsFiltered } =
    useExportAllUserAccountsFiltered();

  /**
   *  On change of multiple search checkbox
   * @param event checkbox on change event
   */
  function checkboxMultipleSearchHandleChange(event) {
    handleClearFilters();
    setIsMultipleSearching(event.target.checked);
  }

  /**
   * Normalize array and string errors
   * @param {Array | string } error error to normalize
   */
  function normalizeError(error) {
    return Array.isArray(error) ? error.filter((n) => n)[0] : error;
  }

  /** Handles exporting dispersion data.*/
  const handleExportAccounts = () => {
    const selectedCompanyId = user.isInternal ? params.companyId : selectedCompany;

    exportAllUserAccountsFiltered({
      companyId: selectedCompanyId,
      lastName: params.lastName,
      firstName: params.firstName,
      email: params.email,
      cuit: params.cuit,
      walletId: params.walletId,
      product: params.product,
      categories: params.categories,
    });
  };

  useEffect(() => {
    if (values.companyId) {
      setValues((prevValues) => ({
        cuit: '',
        dni: '',
        firstName: '',
        lastName: '',
        walletId: '',
        email: '',
        product: '',
        categories: [],
        companyId: prevValues.companyId,
      }));
    }
  }, [values.companyId, setValues]);

  return (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.header}>
        <Typography variant='h4'>{t('EMPLOYEES:SEARCH_CLIENT')}</Typography>
      </Box>
      <Box style={styles.content}>
        <Box style={styles.firstLine(responsive)}>
          <Box style={styles.inputsContainer}>
            {!isMultipleSearching ? (
              <Box style={styles.inputGrid}>
                {fields.map(({ name, label }) => (
                  <Input
                    key={name}
                    label={label}
                    variant='outlined'
                    size='small'
                    name={name}
                    value={values[name]}
                    onChange={handleChange(name)}
                    inputProps={{ onPaste: handleChange(name) }}
                    onBlur={handleBlur(name)}
                    helperText={errors[name]}
                    error={errors[name]}
                    fullWidth
                    sx={{ maxWidth: !responsive ? '143px' : '100%' }}
                  />
                ))}
              </Box>
            ) : (
              <Box style={styles.searchContainerCuil}>
                <Formik
                  initialValues={{ cuit: '' }}
                  validationSchema={cuilValidationSchema(values)}
                >
                  {(inputProps) => (
                    <Box style={styles.inputCuilContainer(responsive)}>
                      <Input
                        name='cuit'
                        value={inputProps.values['cuit']}
                        label={'COMMON:CUIL'}
                        onChange={inputProps.handleChange}
                        onKeyDown={(e) => cuilHandleKeyDown(e, inputProps)}
                        onBlur={inputProps.handleBlur}
                        helperText={inputProps.errors['cuit'] ?? ' '}
                        error={inputProps.errors['cuit']}
                        disabled={maxCuilMultipleSearch}
                        sx={styles.inputCuil(inputProps.errors['cuit'], responsive)}
                      />
                      <Box style={styles.infoCuil}>
                        {!maxCuilMultipleSearch && !errors.cuit && (
                          <Alert severity='info'>{t('EMPLOYEES:MULTIPLE_SEARCH_TOOLTIP')}</Alert>
                        )}
                        {maxCuilMultipleSearch && !errors.cuit && (
                          <Alert severity='info'>{t('EMPLOYEES:MULTIPLE_SEARCH_MAX')}</Alert>
                        )}
                        {errors.cuit && (
                          <Alert severity='info'>{t(normalizeError(errors.cuit))}</Alert>
                        )}
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Box>
            )}
          </Box>
          <Box style={styles.actionsContainer(responsive)}>
            <Button
              customStyle={styles.clearButton}
              text={t('COMMON:CLEAR_FILTER')}
              color='primary'
              variant='link'
              onClick={handleClearFilters}
            />
            <IconButton
              aria-label={t('COMMON:EXPORT')}
              tooltip='COMMON:EXPORT'
              onClick={handleExportAccounts}
              IconComponent={IconFileExport}
              isLoading={isLoadingExportAll}
              disabled={disabledExportAll}
            />
            <IconButton
              aria-label={t('COMMON:SEARCH')}
              tooltip='COMMON:SEARCH'
              onClick={handleSubmit}
              IconComponent={IconSearch}
              isLoading={isLoading}
              disabled={disableButton}
              variant='contained'
            />
          </Box>
        </Box>
        {isMultipleSearching && (
          <FormikProvider value={searchClientFormik}>
            <Box style={styles.secondLine(responsive)}>
              <Box style={styles.ChipContainer}>
                <FieldArray
                  name='cuit'
                  render={({ remove }) =>
                    cuilIsArray &&
                    values.cuit?.map((item, index) => (
                      <Chip
                        m={1}
                        key={index}
                        label={item}
                        onDelete={() => {
                          remove(index);
                        }}
                      />
                    ))
                  }
                />
              </Box>
            </Box>
          </FormikProvider>
        )}
        {!isMultipleSearching && (
          <Box style={styles.secondLine(responsive)}>
            {canSelectCompany && (
              <Select
                label={t('COMMON:COMPANY')}
                value={values.companyId}
                onChange={formikHandleChange('companyId')}
                error={errors.companyId}
                helperText={errors.companyId}
                items={userCompanies}
                placeholder='COMMON:SELECT_COMPANY'
                parseOption={(item) => ({ label: item.description, value: String(item.id) })}
                customStyle={{ maxWidth: !responsive ? '180px' : '100%' }}
              />
            )}
            <Select
              label={t('DISPERSIONS:FAST_DISPERSION:PRODUCT')}
              value={values.product}
              disabled={!hasSelectedACompany}
              onChange={formikHandleChange('product')}
              items={productsData || []}
              isLoading={isProductsLoading}
              parseOption={PRODUCT_PARSE_OPTIONS}
              error={errors.product}
              isError={Boolean(productsError)}
              customStyle={{ maxWidth: !responsive ? '180px' : '100%' }}
            />
            <Select
              name='categories'
              disabled={!hasSelectedACompany}
              label={t('DISPERSIONS:FAST_DISPERSION:CATEGORY')}
              value={values.categories}
              onChange={formikHandleChange('categories')}
              items={categoriesData || []}
              isLoading={isCategoriesLoading}
              parseOption={CATEGORIES_PARSE_OPTIONS}
              isError={Boolean(categoriesError)}
              multiple
              customStyle={{ maxWidth: !responsive ? '180px' : '100%' }}
            />
          </Box>
        )}
      </Box>
      <Box style={styles.footer}>
        <FormControlLabel
          required
          control={
            <Checkbox checked={isMultipleSearching} onChange={checkboxMultipleSearchHandleChange} />
          }
          label={t('EMPLOYEES:MULTIPLE_SEARCH')}
          sx={styles.checkboxInput}
        />
      </Box>
    </Box>
  );
};

export default SearchBoxClient;

SearchBoxClient.propTypes = {
  isLoading: PropTypes.bool,
  params: PropTypes.object,
  retry: PropTypes.func,
  onFiltersFormSubmit: PropTypes.func,
  disabledExportAll: boolean,
};
