import * as Yup from 'yup';
import {
  EXT_USERS_TRANSACTION_TYPES_LABEL_PREFIX,
  EXT_USERS_TRANSACTION_STATUS_LABEL_PREFIX,
  EXTERNAL_USER_EMPLOYEE_TRANSACTION_STATUSES,
  EXTERNAL_USER_EMPLOYEE_TRANSACTION_TYPES,
} from '@globalConstants';
import i18n from '@i18n';
import { formatDate } from '@utils';
import { VALIDATE_DATE, VALIDATE_STRING } from '@validations';

export const validationSchema = Yup.object().shape({
  fromDate: VALIDATE_DATE.required(),
  toDate: VALIDATE_DATE.required(),
  transactionStatus: VALIDATE_STRING,
  transactionType: VALIDATE_STRING,
  accountNumber: VALIDATE_STRING,
});

/**
 * Gets the transaction type label text
 * @param {string} type Transaction type
 * @returns {string}
 */
const getTransactionTypeLabel = (type) => i18n.t(EXT_USERS_TRANSACTION_TYPES_LABEL_PREFIX + type);

/**
 * Gets the transactiosn status label text
 * @param {string} type Status
 * @returns {string}
 */
const getTransactionStatusLabel = (status) =>
  i18n.t(EXT_USERS_TRANSACTION_STATUS_LABEL_PREFIX + status);

/**
 * Generates
 * @param {boolean} isInternal
 * @returns
 */
export const COLUMNS = (isInternal, showAllMovements) => [
  {
    title: 'MOVEMENTS:ACCOUNT_NUMBER',
    content: (movement) => movement.accountNumber,
    hideColumn: !showAllMovements,
  },
  {
    title: 'MOVEMENTS:CONCEPT',
    content: (movement) =>
      isInternal ? movement.transactionType : getTransactionTypeLabel(movement.transactionType),
  },
  { title: 'COMMON:AMOUNT', content: (movement) => movement.amount },
  {
    title: 'COMMON:STATUS',
    content: (movement) =>
      isInternal
        ? movement.transactionStatus
        : getTransactionStatusLabel(movement.transactionStatus),
  },
  {
    title: 'MOVEMENTS:CONCILIATION',
    content: (movement) => movement.conciliationStatus,
    internalFeature: true,
  },
  { title: 'COMMON:DATE', content: (movement) => formatDate(movement.createdAt) },
  {
    title: 'MOVEMENTS:BUSINESS_DATE',
    content: (movement) => movement.businessDate,
    internalFeature: true,
  },
];

export const TRANSACTION_STATES = [
  'AUTHORIZED',
  'CANCELLED',
  'ERROR',
  'OK',
  'PENDING',
  'PENDING_APPROVAL',
  'REJECTED',
  'REVERSED',
];

export const TRANSACTION_TYPES = [
  'ADJUST_CRED_CASHIN',
  'ADJUST_DEB_CASHOUT',
  'BALANCE_INQUIRY_GP',
  'BALANCE_INQUIRY_PRISMA',
  'CANCEL_MISSING_GP',
  'CANCEL_MISSING_PRISMA',
  'CARD_REPLACEMENT',
  'CASHIN',
  'CASHIN_AUTH_CANCEL_FORCED_GP',
  'CASHIN_AUTH_CANCEL_FORCED_PRISMA',
  'CASHIN_AUTH_CANCEL_GP',
  'CASHIN_AUTH_CANCEL_PRISMA',
  'CASHIN_AUTH_PARTIAL_CANCEL_PRISMA',
  'CASHIN_CANCEL_DEPU_GP',
  'CASHIN_FROM_BANK',
  'CASHIN_FROM_CBU',
  'CASHIN_FROM_CVU',
  'CASHIN_FUND_GP',
  'CASHIN_MTT_CANCEL_PRISMA',
  'CASHIN_MTT_CANCEL_GP',
  'CASHIN_PAYPAL',
  'CASHIN_PPCAAS',
  'CASHIN_PROMO',
  'CASHIN_REFUND_PRISMA',
  'CASHIN_TJURIDICA',
  'CASHIN_WITHDRAW_CANCEL_FORCED_GP',
  'CASHIN_WITHDRAW_CANCEL_FORCED_PRISMA',
  'CASHIN_WITHDRAW_CANCEL_GP',
  'CASHIN_WITHDRAW_CANCEL_PRISMA',
  'CASHOUT',
  'CASHOUT_AUTH_FORCED_GP',
  'CASHOUT_AUTH_FORCED_PRISMA',
  'CASHOUT_AUTH_GP',
  'CASHOUT_AUTH_PRISMA',
  'CASHOUT_AUTOFORCED_GP',
  'CASHOUT_EXTRACASH_PRISMA',
  'CASHOUT_FROM_BANK',
  'CASHOUT_FROM_SUBE',
  'CASHOUT_FUND_CANCEL_GP',
  'CASHOUT_LOAN_ADVANCE',
  'CASHOUT_LOAN_OPENPAY',
  'CASHOUT_LOAN_PRECA',
  'CASHOUT_MTT_FORCED_PRISMA',
  'CASHOUT_MTT_FORCED_GP',
  'CASHOUT_MTT_GP',
  'CASHOUT_MTT_PRISMA',
  'CASHOUT_PAY_INSTALLMENT',
  'CASHOUT_PLAYSTATION',
  'CASHOUT_RECOVERY',
  'CASHOUT_RECOVERY_PRISMA',
  'CASHOUT_RECOVERY_GP',
  'CASHOUT_TO_CBU',
  'CASHOUT_TO_CVU',
  'CASHOUT_WITHDRAW_FORCED_GP',
  'CASHOUT_WITHDRAW_FORCED_PRISMA',
  'CASHOUT_WITHDRAW_GP',
  'CASHOUT_WITHDRAW_PRISMA',
  'CREDIN',
  'P2P_TRANSFER',
  'P2P_TRANSFER_REQUEST',
  'QR_PAYMENT_REQUEST',
  'RAPIPAGO_CASHIN',
  'RAPIPAGO_CASHIN_INQUIRY',
  'RAPIPAGO_CASHOUT',
  'RAPIPAGO_CASHOUT_INQUIRY',
  'RAPIPAGO_CASHOUT_PAGO_FACTURA',
  'RAPIPAGO_CASHOUT_PAGO_RECARGA',
  'REPORT',
];

export const DATE_PICKER_PROPS = {
  size: 'medium',
  dateOnly: true,
  readOnly: false,
  disablePast: false,
  calendarViews: ['year', 'month', 'day'],
  fullWidth: true,
};

export const DATE_PICKERS = [
  { name: 'fromDate', label: 'COMMON:FROM_DATE' },
  { name: 'toDate', label: 'COMMON:TO_DATE' },
];

export const ACCOUNT_NUMBER_FILTER = {
  label: 'COMMON:ACCOUNT_NUMBER',
  getInitialValue: () => '',
  name: 'accountNumber',
  inputValidation: VALIDATE_STRING.required(),
  inputType: 'select',
  parseOption: () => (status) => ({
    label: status,
    value: status,
  }),
};

export const FILTER_FIELDS = [
  {
    label: 'COMMON:STATUS',
    getInitialValue: () => '',
    name: 'transactionStatus',
    inputValidation: VALIDATE_STRING.required(),
    inputType: 'select',
    getItems: (isInternal) =>
      isInternal ? TRANSACTION_STATES : EXTERNAL_USER_EMPLOYEE_TRANSACTION_STATUSES,
    parseOption: (isInternal) => (status) => ({
      label: isInternal ? status : getTransactionStatusLabel(status),
      value: status,
    }),
    emptyOption: 'COMMON:ALL',
  },
  {
    label: 'MOVEMENTS:CONCEPT',
    getInitialValue: () => '',
    name: 'transactionType',
    inputValidation: VALIDATE_STRING.required(),
    inputType: 'select',
    getItems: (isInternal) => {
      return isInternal ? TRANSACTION_TYPES : EXTERNAL_USER_EMPLOYEE_TRANSACTION_TYPES;
    },
    parseOption: (isInternal) => (type) => ({
      label: isInternal ? type : getTransactionTypeLabel(type),
      value: type,
    }),
    emptyOption: 'COMMON:ALL',
  },
];
