import { useParams, useLocation } from 'react-router-dom';
import { useDispersionDetails } from '@hooks';
import { MainMenuLayout, FullWidthContent } from '@layouts';
import { Table, GoBackButton } from '@molecules';
import { COLUMNS } from './constants';
import { styles } from './styles';
import { COLUMNS as HEADERS } from '../myDispersions/constants';

/** Dispersion Details screen */
const DispersionDetails = () => {
  const { dispersionId } = useParams();
  const { state: dispersionData } = useLocation();
  const { isLoading, data: dispersionDetailsData } = useDispersionDetails(dispersionId);
  const mergeDispersionData = [{ ...dispersionData, credited: dispersionDetailsData.credited }];
  const detailsHeaders = [
    ...HEADERS(),
    {
      title: 'Acreditados',
      content: (dispersion) => {
        return dispersion.credited;
      },
    },
  ];
  return (
    <MainMenuLayout>
      <FullWidthContent>
        <Table columns={detailsHeaders} items={mergeDispersionData} disableControls />
        <Table
          isLoading={isLoading}
          columns={COLUMNS}
          items={dispersionDetailsData.dispersionResult}
          customStyle={styles.table}
          disableControls
        />
        <GoBackButton variant='outlined' />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default DispersionDetails;
