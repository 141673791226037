import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Box, Skeleton, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { IconSearch } from '@tabler/icons-react';
import { Button, DateTimePicker } from '@atoms';
import { useSelectedCompanyProducts } from '@hooks';
import { SelectFetch } from '@molecules';
import { useCompanyStore } from '@stores';
import { getDatesFromTheLastMonth } from '@utils';
import { DATE_PICKER_PROPS, validationSchema } from '../constants';
import styles from '../styles';

interface SearchBoxConsumptionByCategoryProps {
  filtersOpen?: boolean;
  setFiltersOpen?: (open: boolean) => void;
  isLoading?: boolean;
  params: Record<string, string>;
  onSubmit: (values: Record<string, string | Date>) => void;
}

/**
 * Displays filters
 * @param {SearchBoxConsumptionByCategoryProps} props
 * @param {object} props.params
 */
const SearchBoxConsumptionByCategory: React.FC<SearchBoxConsumptionByCategoryProps> = ({
  isLoading = false,
  params,
  onSubmit,
}: SearchBoxConsumptionByCategoryProps) => {
  const { t } = useTranslation();
  const responsive = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { data: products, isLoading: isLoadingProducts } = useSelectedCompanyProducts();
  const { selectedCompany } = useCompanyStore();
  const previousCompanyRef = useRef<number | null>(null);
  const { fromDate, toDate } = getDatesFromTheLastMonth();

  const initialValues = {
    fromDate,
    toDate,
    product: params?.product || '',
  };

  const { handleChange, setFieldValue, handleSubmit, isValid, errors, values, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  const disableButton = isLoading || !isValid;

  useEffect(() => {
    if (!isLoadingProducts) {
      const product = products.length === 1 ? products[0] : '';
      const values = { fromDate, toDate, product };
      if (
        previousCompanyRef.current !== selectedCompany ||
        (products.length === 1 && !params.product)
      ) {
        resetForm({ values });
        onSubmit({ product });
      }
      previousCompanyRef.current = selectedCompany;
    }
  }, [
    fromDate,
    isLoadingProducts,
    onSubmit,
    params.product,
    products,
    resetForm,
    selectedCompany,
    toDate,
  ]);

  return (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.header}>
        <Typography variant='h4'>{t('MOVEMENTS:FILTERS_TITLE')}</Typography>
      </Box>
      <Box sx={styles.content(responsive)}>
        <Box sx={styles.firstLine(responsive)}>
          <Box sx={styles.inputsContainer(responsive)}>
            <Box sx={styles.boxContainer}>
              <DateTimePicker
                name='fromDate'
                value={values.fromDate}
                handleChange={(date: Date) => setFieldValue('fromDate', date)}
                error={Boolean(errors.fromDate)}
                inputLabel='COMMON:FROM_DATE'
                helperText={errors.fromDate ? String(errors.fromDate) : undefined}
                {...DATE_PICKER_PROPS}
              />
            </Box>
            <Box sx={styles.boxContainer}>
              <DateTimePicker
                name='toDate'
                value={values.toDate}
                handleChange={(date: Date) => setFieldValue('toDate', date)}
                error={Boolean(errors.toDate)}
                inputLabel='COMMON:TO_DATE'
                helperText={errors.toDate ? String(errors.toDate) : undefined}
                {...DATE_PICKER_PROPS}
              />
            </Box>
            <Box sx={styles.boxContainer}>
              {isLoadingProducts ? (
                <Skeleton height={50} sx={styles.skeleton} />
              ) : (
                <SelectFetch
                  name='product'
                  label='COMMON:PRODUCT'
                  onChange={handleChange(name)}
                  value={values.product}
                  error={Boolean(errors.product)}
                  getOptions={useSelectedCompanyProducts}
                  parseOption={(value: string) => ({ text: value, value })}
                  customStyles={styles.selectFetch}
                  helperText={errors.product ? String(errors.product) : undefined}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={styles.actionsContainer(responsive)}>
          <Button
            customStyle={styles.clearButton}
            text={t('COMMON:CLEAR_FILTER')}
            color='primary'
            variant='link'
            onClick={resetForm}
          />
          <Button
            customStyle={styles.searchButton}
            startIcon={
              <SvgIcon>
                <IconSearch />
              </SvgIcon>
            }
            variant='contained'
            color='primary'
            type='submit'
            disabled={disableButton}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchBoxConsumptionByCategory;
