import useSWR from 'swr';
import getCustomerDataByCompanyId from '../services/getCustomerDataByCompanyId';

/**
 * Fetches customer data by company id.
 *
 * @param companyId - The id of the company
 * @returns An object with the customer data, loading state, and error state
 */
export default function useCustomerDataByCompanyId(companyId: number | undefined) {
  const props = useSWR(
    companyId ? ['customer', companyId] : null,
    async ([, id]) => await getCustomerDataByCompanyId(id)
  );

  return props;
}
