import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Box, Button } from '@mui/material';
import { useURLparams } from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { Table } from '@molecules';
import { DEFAULT_ROWS_PER_PAGE, userListColumns } from './constants';
import useGetAllUsersPaginated from './data/useGetAllUsersPaginated';

interface UsersManagmentParams {
  page: string;
  limit: string;
}

/**
 * User management screen
 * @returns The rendered component
 */
const UsersManagmentScreen: FC = () => {
  const { params, setURLparams } = useURLparams<UsersManagmentParams>({
    page: '1',
    limit: String(DEFAULT_ROWS_PER_PAGE),
  });
  const navigate = useNavigate();
  const {
    data: response,
    isLoading,
    handlePageChange,
    handleRowsPerPageChange,
  } = useGetAllUsersPaginated({
    params,
    setURLparams,
  });

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <Box justifyContent={'flex-end'} display={'flex'}>
          <Button variant='contained' onClick={() => navigate('/agregar-usuario')}>
            {t('MENU:USERS_MANAGEMENT:CREATE')}
          </Button>
        </Box>
        <Table
          columns={userListColumns}
          count={response?.pagination?.totalCount || 0}
          isLoading={isLoading}
          items={response?.data || []}
          page={params?.page || 1}
          rowsPerPage={params?.limit || DEFAULT_ROWS_PER_PAGE}
          //TODO: Cast the typing to avoid build errors.
          onPageChange={handlePageChange as () => void}
          onRowsPerPageChange={handleRowsPerPageChange as () => void}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default UsersManagmentScreen;
