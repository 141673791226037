const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    gap: '0.5rem',
    padding: '0.75rem',
    borderRadius: '1rem',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
  containerFirstButtons: (responsive) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    flexWrap: responsive ? 'wrap' : 'nowrap',
  }),
  button: {
    button: {
      padding: '0.5rem',
      fontSize: '14px',
      fontWeight: '400',
      color: 'gray.90',
    },
  },
  containerPopover: {
    padding: '0.5rem',
  },
};

export default styles;
