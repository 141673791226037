import { useState } from 'react';
import useSWR from 'swr';

export interface Client {
  id: string;
  firstName: string;
  lastName: string;
  documentNumber: string;
  dni: string;
  cuit: string;
  walletId: string;
  gender: string | null; // Adjust type based on actual data if known
  birthDate: string | null; // Adjust type based on actual data if known
  email: string;
  mobileNumber: string | null; // Adjust type based on actual data if known
  createdAt: string;
  updatedAt: string;
  type: number;
  segment: string;
  benefitsAvailable: string[];
  username: string;
  registrationStatus: string;
  lastLoginAt: string; // Adjust type based on actual data if known
  customerId: number;
  customerLegalName: string;
}

/**
 * Custom hook for fetching client details.
 * @param {string} username - The username of the client.
 *  @returns {Object} - The response object from the SWR fetch.
 */
const useEmployeeDetails = (clientId: string) => {
  const props = useSWR<Client>(`client/search/${clientId}`, { revalidateOnFocus: false });
  const [assignProductOpen, setAssignProductOpen] = useState(false);

  return { ...props, assignProductOpen, setAssignProductOpen };
};

export default useEmployeeDetails;
