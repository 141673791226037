import useSWR from 'swr';
import getProvincesFromArgentine, { Province } from '../services/getProvinceFromArgentine';

/** Handle fetch all provinces from argentine and save data in cache with key /partner/states */
const useProvinceFromArgentine = () => {
  const props = useSWR<Province[]>('/partner/states', getProvincesFromArgentine);

  return props;
};

export default useProvinceFromArgentine;
