import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';
import { City } from '../../city/services/getCitiesByProvince';

interface PostPostalAddressBody {
  customerId: number;
  street: string;
  number: string;
  floor?: string;
  apartment?: string;
  postalCode: string;
  city: Omit<City, 'id'> | Omit<City, 'name'>;
  stateId: string;
}

/**
 * Service for creating a postal address for a company
 * @param body - The request body.
 */
async function postPostalAddress(body: PostPostalAddressBody) {
  const url = '/partner/address';
  try {
    const response = await apiClient.post(url, body);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.message);
    }
    throw new Error('Unexpected error');
  }
}

export default postPostalAddress;
