/**
 * @param {number} number Amount to be formatted
 * @returns Returns number formatted with thousand separators and decimals only if present
 */
const parseNumber = (number: number) => {
  const hasDecimals = number % 1 !== 0;

  return new Intl.NumberFormat('es-AR', {
    style: 'decimal',
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  }).format(number);
};

export default parseNumber;
