/** Not found postal address exception */
export default class NotFoundPostalAddressException extends Error {
  /**
   * @param {string} [message='MISSING_POSTAL_ADDRESS_TEXT'] the message that will be used for the error
   */
  constructor(message = 'MISSING_POSTAL_ADDRESS_TEXT') {
    super(message);
    this.name = 'NotFoundPostalAddressException';
  }
}
