import { usePagination } from '@hooks';

export interface User {
  id: string;
  name: string;
  email: string;
  status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CREATED';
  companies: string[];
  firstname?: string;
  lastname?: string;
  firstTimeLogin: boolean;
}

export interface PaginationMetadata {
  limit: number;
  totalCount: number;
  totalPages: number;
  currentPage: number;
  previousPage: number | null;
  nextPage: number | null;
  pageLength: number;
}

interface GetAllUsersPaginatedParams {
  params: object;
  setURLparams: () => void;
}

interface ReturnGetAllUserPaginated {
  data: User[];
  pagination: PaginationMetadata;
}

/** Get all users paginated */
const useGetAllUsersPaginated = ({ params, setURLparams }: GetAllUsersPaginatedParams) => {
  const { data: paginatedData, ...props } = usePagination('/auth0/user', {
    params,
    setURLparams,
    paramKeys: ['page', 'limit'],
  });
  const data = paginatedData as ReturnGetAllUserPaginated | undefined;

  return { data, ...props };
};

export default useGetAllUsersPaginated;
