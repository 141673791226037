import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Alert, StrongModalText } from '@atoms';
import { parseAmount } from '@utils';

interface RecoveryConfirmationModalProps {
  isOpen: boolean;
  handleConfirmation: (confirmation: boolean) => void;
  totalAmount: number;
  count: number;
  companyName: string;
  submitLoading: boolean;
  disabled: boolean;
  dispersionDay?: Date;
  failedCount: number;
}

/** ConfirmDisperionModal component */
const RecoveryConfirmationModal: React.FC<RecoveryConfirmationModalProps> = ({
  isOpen,
  handleConfirmation,
  totalAmount,
  count,
  companyName,
  submitLoading,
  disabled,
  failedCount,
}: RecoveryConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Alert
      variant='warning'
      modalVisible={isOpen}
      handleClose={() => handleConfirmation(false)}
      handleSubmit={() => handleConfirmation(true)}
      closeButtonText='COMMON:CANCEL'
      title={t('EMPLOYEES:RETRIEVE_BALANCE:MODAL_TITLE')}
      submitLoading={submitLoading}
      disabled={disabled}
      content={
        <Typography color='gray.90'>
          <Trans
            i18nKey={
              failedCount === 0
                ? 'EMPLOYEES:RETRIEVE_BALANCE:MODAL_MESSAGE'
                : 'EMPLOYEES:RETRIEVE_BALANCE:MODAL_MESSAGE_WITH_WARNING'
            }
            values={{ count, companyName, total: parseAmount(totalAmount) }}
            components={{
              bold: <StrongModalText />,
              br: <br />,
            }}
          />
        </Typography>
      }
    />
  );
};

export default RecoveryConfirmationModal;
