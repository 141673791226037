import useUserCompanies from './useUserCompanies';

interface UserCompanyResponse {
  id: number;
  cuil: string;
  description: string;
  customerId: number;
}

interface GetNameCompanyParams {
  companyId?: string;
  customerId?: string;
}

/**
 * Constructs a type that requires at least one property from `T` to be present.
 *
 * This utility type ensures that at least one of the specified keys `K` of `T` is required,
 * while the remaining properties are optional. If no keys are specified, all keys are considered.
 * @template T - The base type to modify.
 * @template K - The keys of `T` that can be marked as required. Defaults to all keys of `T`.
 *
 * @example
 * type Example = AtLeastOne<{ a: string; b: number; c: boolean }, 'a' | 'b'>;
 * Requires either 'a' or 'b' (or both) to be present, 'c' is optional.
 */
type AtLeastOne<T, K extends keyof T = keyof T> = K extends keyof T
  ? Required<Pick<T, K>> & Partial<Omit<T, K>>
  : never;

type ValidGetNameCompanyParams = AtLeastOne<GetNameCompanyParams, 'companyId' | 'customerId'>;

/**
 * Custom hook that provides a method to get a company's name based on `companyId` or `customerId`.
 *
 * @returns An object containing the `getNameCompany` function.
 */
export const useNameCompany = () => {
  const { data }: { data: UserCompanyResponse[] | null } = useUserCompanies();

  /**
   * Retrieves the name of the company based on either `companyId` or `customerId`.
   *
   * @param {ValidGetNameCompanyParams} params - Object containing either `companyId` or `customerId`.
   * @param {string} [params.companyId] - The ID of the company (optional).
   * @param {string} [params.customerId] - The customer ID of the company (optional).
   *
   * @returns {string | undefined} - The description (name) of the company if found, otherwise `undefined`.
   */
  const getNameCompany = ({
    companyId,
    customerId,
  }: ValidGetNameCompanyParams): string | undefined => {
    return data?.find(({ id, customerId: idCustomer }) =>
      companyId ? id === Number(companyId) : idCustomer === Number(customerId)
    )?.description;
  };

  return { getNameCompany };
};

export default useNameCompany;
