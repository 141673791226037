const styles = {
  spinner: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    color: 'primary',
    margin: '5rem auto',
  },
};

export const propertyListItemStyle = {
  textContainer: {
    flexDirection: 'column',
    gap: 0,
  },
};

export default styles;
