import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { ProductName } from '@globalConstants';
import ProductItem from './ProductItem';

interface ProductsAvailableListProps {
  products: ProductName[];
}

/**
 * Component that renders a list of available products for a given company.
 *
 * @param products The list of products to be displayed.
 * @returns The rendered component.
 */
const ProductsAvailableList: FC<ProductsAvailableListProps> = ({ products }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant='h2'>{t('CLIENTS:DETAIL:PRODUCTS_AVAILABLE:TITLE')}</Typography>
      <Stack direction='column' alignItems='flex-start' gap={2}>
        {products.map((product) => (
          <ProductItem key={product} product={product} />
        ))}
      </Stack>
    </Stack>
  );
};

export default ProductsAvailableList;
