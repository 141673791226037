import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useSelectedCompanyDetails, useUser } from '@hooks';
import { BoxContainer } from '@layouts';
import { SelectCompany } from '@organisms';
import { CUSTOMER_TYPES } from './constants';

/**
 * SelectedCompany
 * @param {object} props
 *  @param {boolean=} props.disabled - property to disinhabit the Select company when isLoading is true
 *  @param {boolean=} props.disableHeader - Hides the header if true, used for non-internal users.
 *  @param {boolean=} props.showCustomerType - Displays the customer type when available.
 */
const SelectCompanyCard = ({
  disabled = false,
  disableHeader = false,
  showCustomerType = false,
}) => {
  const { t } = useTranslation();
  const { data } = useSelectedCompanyDetails();
  const { user } = useUser();
  return (
    <>
      {user.isInternal ? (
        <BoxContainer>
          <Typography variant='h4'>{t('COMMON:COMPANY')}</Typography>
          <SelectCompany disabled={disabled} />
          {data?.customerType && showCustomerType && (
            <Typography variant='h5'>
              {t('COMMON:CUSTOMER_TYPE_LABEL', {
                customerType: CUSTOMER_TYPES[data.customerType],
              })}
            </Typography>
          )}
        </BoxContainer>
      ) : (
        <>
          {disableHeader ? null : (
            <BoxContainer>
              <Typography variant='h4'>
                {data?.description
                  ? t('DISPERSIONS:HEADING', { name: data.description })
                  : t('COMMON:HELLO')}
              </Typography>
            </BoxContainer>
          )}
        </>
      )}
    </>
  );
};

SelectCompanyCard.propTypes = {
  disabled: PropTypes.bool,
  disableHeader: PropTypes.bool,
  showCustomerType: PropTypes.bool,
};

export default SelectCompanyCard;
