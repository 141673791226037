import { useState } from 'react';
import { isAxiosError } from 'axios';
import { userManagementFormSchemaType } from '@modules/userManagementForm/UserManagementForm.schema';
import postCreateUser from '../services/postCreateUser';

interface AlertMessage {
  title: string;
  description: string;
  variant: 'error' | 'success';
}

interface Status {
  loading: boolean;
  alertMessage: AlertMessage;
  success: boolean;
  error: boolean;
}

type MessageType = {
  [key: string]: AlertMessage;
};

/**
 * Create Auth0 user state hook
 *
 * @remarks
 * this hook handles the screen status relationated to the service endpoint and response
 * @param reset - Function to reset form values
 */
const useCreateUser = (reset: () => void) => {
  const MESSAGES: MessageType = {
    ERROR_GENERIC: {
      title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
      description: 'COMMON:ERRORS:IT_ERROR',
      variant: 'error',
    },
    ERROR_EMAIL: {
      title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:ERROR_DUPLICATED_EMAIL',
      variant: 'error',
    },
    SUCCESS: {
      title: 'USERS_MANAGEMENT:ALERT_MESSAGES:TITLE_SUCCESS',
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:SUCCESS',
      variant: 'success',
    },
  } as const;

  const [status, setStatus] = useState<Status>({
    loading: false,
    success: false,
    error: false,
    alertMessage: MESSAGES.ERROR_GENERIC,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  /**
   * Function to set the error state.
   * @param {boolean} error - The error state.
   */
  const setIsError = (error: boolean) => {
    setStatus((prevState) => ({ ...prevState, error }));
  };

  /**
   * Function to set the sucess state.
   * @param {boolean} success - The success state.
   */
  const setIsSuccess = (success: boolean) => {
    setStatus((prevState) => ({ ...prevState, success }));
  };

  /**
   * Create Auth0 user service handler
   * @async
   * @param body - request payload
   */
  async function createUser(body: userManagementFormSchemaType) {
    try {
      setStatus((prevState) => ({ ...prevState, loading: true }));
      await postCreateUser(body);
      setStatus({
        loading: false,
        error: false,
        success: true,
        alertMessage: MESSAGES.SUCCESS,
      });
      reset();
    } catch (error) {
      const alertMessage =
        isAxiosError(error) && error?.response?.status === 409
          ? MESSAGES.ERROR_EMAIL
          : MESSAGES.ERROR_GENERIC;
      setStatus({ error: true, success: false, loading: false, alertMessage });
    } finally {
      setIsModalOpen(false);
    }
  }

  const { loading: isLoading, success: isSuccess, error: isError, alertMessage } = status;

  return {
    isLoading,
    isSuccess,
    isError,
    alertMessage,
    setIsSuccess,
    setIsError,
    createUser,
    isModalOpen,
    setIsModalOpen,
  };
};

export default useCreateUser;
