import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

export interface City {
  id: string;
  name: string;
}

/** Fetch all provinces from argentine from /partner/states */
async function getCitiesByProvince(provinceId: string): Promise<City[]> {
  try {
    const response = await apiClient.get(`/partner/cities?stateId=${provinceId}`);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.message);
    }
    throw new Error('Unexpected error');
  }
}

export default getCitiesByProvince;
