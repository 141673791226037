import { useState } from 'react';
import { apiClient } from '@config/api';

/**
 * Custom hook to manage prospect creation and confirmation.
 * @returns {object} Object containing data, loading state, errors, and functions for creating and confirming prospects.
 */
function useAddClient() {
  const ERROR_MESSAGE_GENERIC = {
    title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
    description: 'COMMON:ERRORS:DEFAULT_ERROR',
    details: [],
  };
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: false,
    alertMessage: ERROR_MESSAGE_GENERIC,
  });

  /**
   * Function to set the error state.
   * @param {boolean} error - The error state.
   */
  const setIsError = (error) => {
    setStatus({ ...status, error });
  };

  /**
   * Function to set the error state.
   * @param {boolean} success - The success state.
   */
  const setIsSuccess = (success) => {
    setStatus({ ...status, success });
  };

  /**
   * Function to get the error message from the response.
   * @param {object} data - The response data.
   * @returns {string[]} An array of error messages.
   */
  const getSuccessWithAlertMessage = (data) => {
    let accumulatedErrors = [];
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const obj = data[key];
        if (obj.success === false && obj.description.errors) {
          obj.description.errors.forEach((error) => {
            const errorMessage =
              typeof error.message === 'string' ? error.message : error.message.message;
            if (key === 'groupInfo') {
              accumulatedErrors.push('CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERIC_GROUP_INFO');
            }
            if (key === 'companyProcessResult') {
              accumulatedErrors.push(
                errorMessage === 'Company exists'
                  ? 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:EXIST_COMPANY_PROCESS_RESULT'
                  : errorMessage.includes('Invalid legal identification')
                  ? 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:INVALID_LEGAL_IDENTIFICATION'
                  : 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERY_COMPANY_PROCESS_RESULT'
              );
            }
            if (key === 'segmentResult') {
              accumulatedErrors.push(
                /SEGMENT/.test(errorMessage) && /EXISTS/.test(errorMessage)
                  ? 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:EXIST_SEGMENT_RESULT'
                  : 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERIC_SEGMENT_RESULT'
              );
            }
            if (key === 's3Folders') {
              accumulatedErrors.push('CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERIC_S3_FOLDERS');
            }
            if (key === 'prospectAdress') {
              accumulatedErrors.push(
                errorMessage.includes('address already exist')
                  ? 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:EXIST_PROSPECT_ADRESS'
                  : 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERIC_PROSPECT_ADRESS'
              );
            }
          });
        }
      }
    }
    return accumulatedErrors;
  };

  /**
   * Extracts error messages from a nested object and replaces specific messages with predefined values.
   * @param {Array<Object>} data - The array of objects containing error messages.
   * @returns {Array<string>} An array containing the modified error messages.
   */
  const getErrorAlertMessage = (data) => {
    let accumulatedErrors = [];

    /** * Recursively extracts error messages from nested objects.*/
    function extractErrorMessages(obj) {
      if ('message' in obj) {
        let errorMessage = obj.message;
        switch (errorMessage) {
          case 'invalid legal identification':
            errorMessage = 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:INVALID_LEGAL_IDENTIFICATION';
            break;
          case 'Company already exist.':
            errorMessage = 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:EXIST_COMPANY_PROCESS_RESULT';
            break;
          case 'CompanyKey already exist.':
            errorMessage = 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:EXIST_SEGMENT_RESULT';
            break;
          case 'GroupName already exist.':
            errorMessage = 'CLIENTS:CREATE_CLIENT:ERROR_DETAILS:EXIST_GROUP_NAME';
            break;
          default:
            break;
        }
        accumulatedErrors.push(errorMessage);
      }

      if ('errors' in obj) {
        obj.errors.forEach((error) => {
          extractErrorMessages(error);
        });
      }
    }

    data.forEach((entry) => {
      extractErrorMessages(entry);
    });

    return accumulatedErrors;
  };

  /**
   * Function to handle customer group
   */
  const handleCustomerGroup = (companyGroup) => {
    if (typeof companyGroup === 'object') {
      return Object.keys(companyGroup).length
        ? { id: companyGroup.id, name: companyGroup.title }
        : undefined;
    } else {
      return companyGroup ? { name: companyGroup } : undefined;
    }
  };

  /**
   * Function to create a fast dispersion.
   * @param {prospects} body - The list prospects for fast dispersion.
   * @param {prospects} amount - The amount body for fast dispersion.
   * @param {prospects} companyId - The companyId for fast dispersion.
   * @returns {Promise<object>} A Promise that resolves with the response data once the mutation is complete.
   */
  const addClient = async ({ companyGroup, companyName, cuit, customerType, segment }) => {
    setStatus({
      loading: false,
      success: false,
      error: false,
      alertMessage: ERROR_MESSAGE_GENERIC,
    });
    try {
      const customerGroup = companyGroup && handleCustomerGroup(companyGroup);
      const { data } = await apiClient.post('/partner/customer/add-client', {
        ...(customerGroup && { customerGroup }),
        customerName: companyName,
        cuit,
        companyKey: segment,
        customerType: customerType.value,
      });

      const successWithalertMessage = getSuccessWithAlertMessage(data);
      setStatus({
        loading: false,
        success: true,
        error: false,
        alertMessage: {
          description:
            successWithalertMessage.length > 0
              ? 'CLIENTS:CREATE_CLIENT:SUCCESS_WITH_ERROR_DESCRIPTION'
              : 'CLIENTS:CREATE_CLIENT:SUCCESS_DESCRIPTION',
          details: successWithalertMessage,
        },
      });
      return data;
    } catch ({ response }) {
      const errorAlertMessage = getErrorAlertMessage(response.data.errors);
      setStatus({
        loading: false,
        success: false,
        error: true,
        alertMessage: {
          title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
          description:
            errorAlertMessage.length > 0
              ? 'CLIENTS:CREATE_CLIENT:ERROR_DESCRIPTION'
              : 'COMMON:ERRORS:DEFAULT_ERROR',
          details: errorAlertMessage,
        },
      });
    }
  };

  const { loading: isLoading, success: isSuccess, error: isError, alertMessage } = status;

  return { isLoading, isSuccess, isError, alertMessage, setIsSuccess, setIsError, addClient };
}

export default useAddClient;
