const styles = {
  main: {
    flexGrow: 1,
    py: 8,
  },
  stack: {
    gap: '9px',
    flexDirection: 'row',
  },
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '190px',
  },
  emailMaxWidth: {
    maxWidth: '200px',
  },
  walletMaxWidth: {
    maxWidth: '190px',
  },
  productMaxWidth: {
    maxWidth: '190px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  cuitMaxWidth: {
    maxWidth: '100px',
  },
  statusPill: {
    maxWidth: '120px',
    padding: '5px 0',
    borderRadius: '50px',
    textAlign: 'center',
  },
  REGISTERED: { background: ({ palette }) => palette.success[600] },
  PROSPECT: { background: ({ palette }) => palette.warning[600] },
  ONBOARDING: { background: ({ palette }) => palette.info[600] },
  UNKNOWN: { background: ({ palette }) => palette.neutral[300] },
  DELETED: { background: ({ palette }) => palette.error[500] },
};
export default styles;
