import useSWR, { SWRResponse } from 'swr';
import { useGlobalErrorStore } from '@stores';

interface Company {
  id: number;
  legalName: string;
  legalIdentification: string;
  fkCustomerGroup: number | null;
}

interface ErrorResponse {
  response?: {
    status: number;
    data?: {
      errors?: string[];
    };
  };
}

/**
 * Fetches the customers list
 * @returns {SWRResponse<Company[], ErrorResponse>} The response from SWR, including data and error states
 */
const usePartnerCustomers = (): SWRResponse<Company[], ErrorResponse> => {
  const { setError, setShowError } = useGlobalErrorStore();

  /**
   * If companies request fails
   * @param {ErrorResponse} error The error object from the failed request
   */
  function onError(error: ErrorResponse) {
    const response = error?.response;

    if (response?.status === 404 && response?.data?.errors?.[0]) {
      setError({
        title: 'DISPERSIONS:ERRORS:INVALID_COMPANIES_TITLE',
        description: 'DISPERSIONS:ERRORS:INVALID_COMPANIES_DESCRIPTION',
      });
    } else {
      setError({
        title: 'DISPERSIONS:ERRORS:UNABLE_TO_FETCH_COMPANIES_TITLE',
        description: 'DISPERSIONS:ERRORS:UNABLE_TO_FETCH_COMPANIES_DESCRIPTION',
      });
    }

    setShowError(true);
  }

  const props = useSWR<Company[], ErrorResponse>('/partner/customers', {
    onError,
    revalidateOnFocus: false,
  });

  return props;
};

export default usePartnerCustomers;
