import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

export interface CustomerData {
  id: number;
  legalName: string;
  legalIdentification: string;
  fkCustomerGroup: number;
}

/** Fetch customer data by company id
 * @param companyId - The id of the company
 */
async function getCustomerDataByCompanyId(companyId: number): Promise<CustomerData> {
  try {
    const { data: customerData } = await apiClient.get<CustomerData>(
      `/partner/company/${companyId}/customer`
    );
    return customerData;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.message);
    }
    throw new Error('Unexpected error');
  }
}

export default getCustomerDataByCompanyId;
