export const DEFAULT_ACCEPTED_FILES_CSV = {
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

/**
 * Gets the message i18n alias of a given CSV row validation error code
 * @param {*} code The error code corresponding to a CSV line
 * @returns
 */
export function CSV_LINE_ERRORS_MESSAGES(code) {
  const missing_column = 'missing_column_';
  if (code.startsWith(missing_column)) {
    return ['COMMON:VALIDATIONS:MISSING_COLUMN', { column: code.substring(missing_column.length) }];
  }

  const staticErrors = {
    beneficio_must_not_be_empty: 'COMMON:VALIDATIONS:BENEFICIO_MUST_NOT_BE_EMPTY',
    category_must_not_be_empty: 'COMMON:VALIDATIONS:CATEGORY_MUST_NOT_BE_EMPTY',
    category_not_allowed: 'COMMON:VALIDATIONS:CATEGORY_NOT_ALLOWED',
    cuit_invalid_length: 'COMMON:VALIDATIONS:CUIL_INVALID_LENGTH',
    cuit_must_be_number: 'COMMON:VALIDATIONS:CUIT_MUST_BE_A_NUMBER',
    cuit_must_not_be_empty: 'COMMON:VALIDATIONS:CUIT_MUST_NOT_BE_EMPTY',
    dni_invalid_length: 'COMMON:VALIDATIONS:DNI_INVALID_LENGTH',
    dni_must_be_number: 'COMMON:VALIDATIONS:DNI_MUST_BE_A_NUMBER',
    dni_must_not_be_empty: 'COMMON:VALIDATIONS:DNI_MUST_NOT_BE_EMPTY',
    dni_or_cuit_mismatch: 'COMMON:VALIDATIONS:DNI_OR_CUIT_MISMATCH',
    email_must_be_not_empty: 'COMMON:VALIDATIONS:EMAIL_MUST_NOT_BE_EMPTY',
    invalid_email: 'COMMON:VALIDATIONS:INVALID_EMAIL',
    invalid_dni_or_cuit: 'COMMON:VALIDATIONS:INVALID_DNI_OR_CUIT',
    invalid_total_value: 'COMMON:VALIDATIONS:INVALID_TOTAL_VALUE',
    monto_must_be_not_empty: 'COMMON:VALIDATIONS:AMOUNT_MUST_NOT_BE_EMPTY',
    name_must_be_not_empty: 'COMMON:VALIDATIONS:NAME_MUST_BE_NOT_EMPTY',
    name_must_be_a_string: 'COMMON:VALIDATIONS:NAME_MUST_BE_A_STRING',
    lastname_must_be_not_empty: 'COMMON:VALIDATIONS:LASTNAME_MUST_BE_NOT_EMPTY',
    lastname_must_be_a_string: 'COMMON:VALIDATIONS:LASTNAME_MUST_BE_A_STRING',
    repeated_dni: 'COMMON:VALIDATIONS:REPEATED_DNI',
    repeated_cuil: 'COMMON:VALIDATIONS:REPEATED_CUIL',
    repeated_email: 'COMMON:VALIDATIONS:REPEATED_EMAIL',
    product_must_not_be_empty: 'COMMON:VALIDATIONS:PRODUCT_MUST_NOT_BE_EMPTY',
    product_not_allowed: 'COMMON:VALIDATIONS:PRODUCT_NOT_ALLOWED',
    tipo_tarjeta_must_not_be_empty: 'COMMON:VALIDATIONS:TIPO_TARJ_MUST_NOT_BE_EMPTY',
    monto_must_be_positive_number: 'COMMON:VALIDATIONS:AMOUNT_MUST_BE_POSITIVE_NUMBER',
  };

  return staticErrors[code];
}
