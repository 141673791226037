import { useCallback, useState } from 'react';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';
import { useCompanyStore } from '@stores';

interface FilterPartnerTransaction {
  fromDate?: string;
  toDate?: string;
  customerTransactionType?: string;
  movementType?: string;
}

/** Custom hook to manage export all partner transactions  */
const useDownloadPartnerTransactions = () => {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState<string>();
  const { selectedCompany: companyId } = useCompanyStore();

  const downloadPartnerTransactions = useCallback(
    async (filters: FilterPartnerTransaction) => {
      setIsloading(true);
      setError(undefined);
      try {
        const filtersDigets = {
          ...(filters.fromDate && { fromDate: filters.fromDate }),
          ...(filters.toDate && { toDate: filters.toDate }),
          ...(filters.customerTransactionType === 'ADJUSTMENT_DEBIT' && {
            customerTransactionType: 'ADJUSTMENT',
            movementType: 'DEBIT',
          }),
          ...(filters.customerTransactionType === 'ADJUSTMENT_CREDIT' && {
            customerTransactionType: 'ADJUSTMENT',
            movementType: 'CREDIT',
          }),
          ...(filters.customerTransactionType &&
            !['ADJUSTMENT_DEBIT', 'ADJUSTMENT_CREDIT'].includes(
              filters.customerTransactionType
            ) && {
              customerTransactionType: filters.customerTransactionType,
            }),
        };
        const response = await apiClient.get(`/export/partner/transactions/${companyId}`, {
          params: filtersDigets,
          responseType: 'blob',
        });

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Movimientos_saldo.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (error) {
        if (isAxiosError(error)) {
          setError(error.message);
        }
      } finally {
        setIsloading(false);
      }
    },
    [companyId]
  );

  return { isLoading, downloadPartnerTransactions, error };
};

export default useDownloadPartnerTransactions;
