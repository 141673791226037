import {
  VALIDATE_OBJECT,
  VALIDATE_REQUIRED_STRING,
  VALIDATE_STRING_OF_NUMBERS,
  Yup,
} from '@validations';

export const ERROR_AT_LEAST_ONE_FIELD = 'COMMON:VALIDATIONS:AT_LEAST_ONE_FIELD_REQUIRED';

export const validationSchema = VALIDATE_OBJECT({
  cuit: VALIDATE_STRING_OF_NUMBERS.min(9, 'COMMON:VALIDATIONS:CUIT_INVALID_LENGTH')
    .max(12, 'COMMON:VALIDATIONS:CUIT_INVALID_LENGTH')
    .required('COMMON:VALIDATIONS:REQUIRED_FIELD')
    .trim(),
  companyName: VALIDATE_REQUIRED_STRING.trim(),
  companyGroup: Yup.mixed(),
  customerType: Yup.mixed().required('COMMON:VALIDATIONS:REQUIRED_FIELD'),
  segment: VALIDATE_REQUIRED_STRING.trim(),
});

export const FIELDS = [
  { name: 'cuit', label: 'COMMON:CUIT', type: 'input' },
  { name: 'companyName', label: 'COMMON:COMPANY_NAME', type: 'input' },
  { name: 'companyGroup', label: 'COMMON:COMPANY_GROUP', type: 'autocomplete' },
  { name: 'customerType', label: 'COMMON:CUSTOMER_TYPE', type: 'autocomplete' },
  { name: 'segment', label: 'COMMON:SEGMENT', type: 'input' },
];

export const CUSTOMER_TYPES = [
  { value: 'PUBLIC', label: 'Público' },
  { value: 'PRIVATE', label: 'Privado' },
];
