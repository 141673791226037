import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Button, Alert } from '@atoms';
import {
  useProspectList,
  useCreateXlsx,
  useFastDispersion,
  useSelectedCompanyProducts,
  useSelectedCompanyCategories,
  useCompanyDetails,
  useExportAllAccountsFiltered,
} from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { Table } from '@molecules';
import { FormDispersion, FormModal, DispersionConfirmationModal } from '@organisms';
import { useCompanyStore } from '@stores';
import CompanyBalanceCard from './CompanyBalanceCard';
import {
  COLUMNS,
  PARSE_PRODUCT_OPTIONS,
  PARSE_CATEGORY_OPTIONS,
  VALIDATION_SCHEMA,
  CONFIG_FIELDS,
  VALIDATION_AMOUNT_SCHEMA,
  CONFIG_AMOUNT_FIELD,
} from './constants';
import styles from './styles';
import SelectCompanyCard from '../SelectCompanyCard';

/**
 * Component for Fast Dispersion.
 * @returns {JSX.Element} - Fast Dispersion component.
 */
const FastDispersion = () => {
  const { t } = useTranslation();
  const { selectedCompany } = useCompanyStore();
  const { data: companyData } = useCompanyDetails(selectedCompany);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isShowAmountModal, setIsShowAmountModal] = useState(false);

  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const {
    data,
    isLoading,
    handleRowsPerPageChange,
    handlePageChange: onPageChange,
    params,
    setParams,
  } = useProspectList(selectedCompany, setSelectedItems);
  const { isLoading: isLoadingCreateXlsx, createXlsx } = useCreateXlsx();
  const {
    isLoading: isLoadingDispersion,
    fastDispersion,
    createFastDispersionWithAllFilteredData,
    confirmFastDispersion,
    isError,
    setIsError,
    errorMessage,
  } = useFastDispersion();

  const { isLoading: isLoadingExportAll, exportAllAccountsFiltered } =
    useExportAllAccountsFiltered();

  const [confirmDispersion, setConfirmDispersion] = useState({
    openModal: false,
    id: null,
    total: null,
    count: null,
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const product = params?.product || null;
  const categories = params?.categories?.split(',') || [];
  const disabled = !selectedItems.length || isLoading || isLoadingDispersion;
  const disabledFilters = isLoading || !selectedCompany;

  /** Handles applying products value. */
  const handleProduct = ({ target }) => {
    setParams({ product: target.value, page: 1 });
    setSelectedItems([]);
  };

  /** Handles applying categories value. */
  const handleCategories = ({ target }) => {
    const newValue = target.value.includes('GENERIC') ? ['GENERIC'] : target.value;
    setParams({ categories: newValue.join(','), page: 1 });
    setSelectedItems([]);
  };

  /** Handles exporting dispersion data.*/
  const handleExportDispersion = () => {
    if (isSelectedAll) {
      exportAllAccountsFiltered(selectedCompany, {
        cuil: params.cuil,
        firstName: params.firstName,
        lastName: params.lastName,
        categories: params.categories,
        product: params.product,
      });
    } else {
      createXlsx(selectedItems);
    }
  };

  /** Handles moving to the next step. */
  const handleNext = () => {
    setIsShowAmountModal(true);
  };

  /** Handles select all data */
  const handleSelectAll = () => {
    setIsSelectedAll((prev) => {
      if (prev) {
        setSelectedItems([]);
      }

      return !prev;
    });
  };

  /** Handle action when page change */
  const handlePageChange = (page) => {
    onPageChange(page);
    if (isSelectedAll) {
      setIsSelectedAll(false);
      setSelectedItems([]);
    }
  };

  /** Handles applying filters. */
  const handleFilters = (data) => {
    setParams({ ...data, page: 1 });
  };

  /**
   * Handles clearing the filters.
   */
  const handlerClearFilter = () => {
    setParams({ firstName: '', product: '', lastName: '', categories: '', cuil: '', page: 1 });
  };

  /** Handle submit fast dispersion. */
  const handleSubmit = async ({ amount }) => {
    let fastDispersionResponse;

    setIsShowAmountModal(false);

    if (isSelectedAll) {
      fastDispersionResponse = await createFastDispersionWithAllFilteredData({
        allowDispersion: true,
        companyId: selectedCompany,
        amount,
        filters: {
          cuil: params.cuil,
          firstName: params.firstName,
          lastName: params.lastName,
          categories: params.categories,
          product: params.product,
        },
      });
    } else {
      fastDispersionResponse = await fastDispersion({
        employees: selectedItems,
        amount,
        companyId: selectedCompany,
      });
    }

    const { dispersionId, total, count } = fastDispersionResponse;

    if (dispersionId && total && count) {
      setConfirmDispersion({
        openModal: true,
        id: dispersionId,
        total,
        count,
      });
    }
  };

  /** Handle submit conformation fast dispersion. */
  const handleDispersionConfirm = async (confirm) => {
    const data = await confirmFastDispersion({
      dispersionId: confirmDispersion.id,
      confirm,
    });
    if (data) {
      if (confirm) {
        setSelectedItems([]);
        setIsSelectedAll(false);
        setIsSuccess(true);
      }
    }
    setConfirmDispersion({ openModal: false, id: null, total: null, count: null });
  };

  const { data: productsData } = useSelectedCompanyProducts();
  const { data: categoriesData } = useSelectedCompanyCategories();

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <SelectCompanyCard disabled={isLoading} />
        <CompanyBalanceCard />
        <FormDispersion
          validationSchema={VALIDATION_SCHEMA}
          configFields={CONFIG_FIELDS}
          initialValues={params}
          onSubmit={handleFilters}
          clearFilters={handlerClearFilter}
          disabledFilters={disabledFilters}
          textButtonConfirm='COMMON:FILTER'
          selectProductlabel={t('DISPERSIONS:FAST_DISPERSION:PRODUCT')}
          selectProductvalue={product}
          selectProductdisabled={disabledFilters}
          selectProductonChange={handleProduct}
          selectProductitems={productsData || []}
          selectProductparseOption={PARSE_PRODUCT_OPTIONS}
          selectCategorylabel={t('DISPERSIONS:FAST_DISPERSION:CATEGORY')}
          selectCategoryvalue={categories}
          selectCategorydisabled={disabledFilters}
          selectCategoryonChange={handleCategories}
          selectCategoryitems={categoriesData || []}
          selectCategoryparseOption={PARSE_CATEGORY_OPTIONS}
        />
        <Table
          showCheckbox
          checkedItemProp={'accountId'}
          columns={COLUMNS}
          count={data?.totalCount || 0}
          disableControls={!data?.page?.length}
          isSelectedAll={isSelectedAll}
          isLoading={isLoading}
          items={data?.page || []}
          page={params?.page || 1}
          rowsPerPage={params.limit || '25'}
          selectedItems={selectedItems}
          size='large'
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          isExportFile
          exportFileText={t('DISPERSIONS:FAST_DISPERSION:EXPORT_BUTTON')}
          exportFileOnClick={handleExportDispersion}
          exportFileIsLoading={isLoadingCreateXlsx || isLoadingExportAll}
          onSelectAll={handleSelectAll}
          setIsSelectedAll={setIsSelectedAll}
          setSelectedItems={setSelectedItems}
        />
        <Stack sx={styles.toolbarContainer}>
          <Button
            variant='contained'
            onClick={handleNext}
            content={t('DISPERSIONS:FAST_DISPERSION:NEXT_BUTTON')}
            disabled={disabled}
            isLoading={isLoadingDispersion}
          />
        </Stack>
        <FormModal
          title='DISPERSIONS:FAST_DISPERSION:TITLE_AMOUNT'
          description='DISPERSIONS:FAST_DISPERSION:DESCRIPTION_AMOUNT'
          isOpen={isShowAmountModal}
          validationSchema={VALIDATION_AMOUNT_SCHEMA}
          configFields={CONFIG_AMOUNT_FIELD}
          onClose={() => setIsShowAmountModal(false)}
          initialValues={{ amount: '' }}
          showClearFilter={false}
          onSubmit={handleSubmit}
        />
        <DispersionConfirmationModal
          openModal={confirmDispersion.openModal}
          handleConfirmation={handleDispersionConfirm}
          total={confirmDispersion.total}
          count={confirmDispersion.count}
          companyName={companyData?.description}
          submitLoading={isLoadingDispersion}
        />
        <Alert
          modalVisible={isError}
          handleClose={() => setIsError(false)}
          variant='error'
          title={errorMessage.title}
          description={errorMessage.description}
        />
        <Alert
          modalVisible={isSuccess}
          handleClose={() => setIsSuccess(false)}
          variant='success'
          title={'DISPERSIONS:FAST_DISPERSION:DISPERSIONS_SUCCESS_TITLE_INSTANT'}
          description={'DISPERSIONS:FAST_DISPERSION:DISPERSIONS_SUCCESS_BODY_INSTANT'}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default FastDispersion;
