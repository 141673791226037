import { useCallback, useState } from 'react';
import { AlertColor } from '@mui/material';

/**
 * Custom hook for displaying a Snackbar.
 * @returns {Object} Functions and components related to the Snackbar.
 */
const useSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>();

  /**
   * Displays the Snackbar with the provided message and severity.
   * @param {string} message - The message to display in the Snackbar.
   * @param {string} severity - The type of the Snackbar.
   */
  const showSnackbar = useCallback((message: string, severity: AlertColor) => {
    setMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  /**
   * Handles the close event of the Snackbar.
   */
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return {
    showSnackbar,
    snackbarProps: {
      open: snackbarOpen,
      handleClose: handleSnackbarClose,
      message,
      severity,
    },
  };
};

export default useSnackbar;
