import { useCallback, useState } from 'react';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

export interface ExportAllUserMovementsByAccountFilters {
  fromDate?: string;
  toDate?: string;
  transactionType?: string;
  transactionStatus: string;
}
interface ExportAllUserMovementsByAccountNumberReturn {
  isLoading: boolean;
  exportAllUserMovementsByAccountNumber: (
    accountNumber: string,
    accountId: string,
    filters: ExportAllUserMovementsByAccountFilters
  ) => Promise<void>;
  error?: string;
}

/**
 * Custom hook to manage export all movements by Account Number to xlsx file
 * @returns {isLoading, exportAllMovements, error}
 */
const useExportAllUserMovementsByAccountNumber =
  (): ExportAllUserMovementsByAccountNumberReturn => {
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState<string>();

    const exportAllUserMovementsByAccountNumber = useCallback(
      async (
        accountNumber: string,
        accountId: string,
        filters: ExportAllUserMovementsByAccountFilters
      ) => {
        setIsloading(true);
        setError(undefined);
        try {
          const response = await apiClient.get(`/export/accountMovements/${accountNumber}`, {
            params: { accountId, ...filters },
            responseType: 'blob',
          });

          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'movimientos.xlsx');
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        } catch (error) {
          if (isAxiosError(error)) {
            setError(error.message);
          }
        } finally {
          setIsloading(false);
        }
      },
      []
    );

    return { isLoading, exportAllUserMovementsByAccountNumber, error };
  };

export default useExportAllUserMovementsByAccountNumber;
