import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { PropertyListItem } from '@atoms';
import { OPERATIONS, CLIENT } from '@globalConstants';
import { CompanyPostalAddress } from '@modules/clients/data/PlacesRepository/address/services/getPostalAddressByCustomerId';
import styles, { propertyListItemStyle } from './PostalAddressDetail.styles';

interface PostalAddressDetailProps {
  companyPostalAddress: CompanyPostalAddress | undefined;
  isExpanded: boolean;
  isLoading: boolean;
  isOperationRole: boolean;
}

/** Render company details address */
const PostalAddressDetail: FC<PostalAddressDetailProps> = ({
  companyPostalAddress,
  isExpanded,
  isLoading,
  isOperationRole,
}) => {
  const { t } = useTranslation();

  if (isLoading) return <CircularProgress sx={styles.spinner} size={48} />;

  if (!companyPostalAddress)
    return (
      <Typography>
        {isOperationRole
          ? t(`CLIENTS:DETAIL:DONT_HAVE_POSTAL_ADDRESS:${OPERATIONS.toUpperCase()}`)
          : t(`CLIENTS:DETAIL:DONT_HAVE_POSTAL_ADDRESS:${CLIENT.toUpperCase()}`)}
      </Typography>
    );

  if (isExpanded) {
    const floorAndAparment =
      companyPostalAddress &&
      (companyPostalAddress.floor || companyPostalAddress.apartment
        ? `${companyPostalAddress.floor ?? ''}/${companyPostalAddress.apartment ?? ''}`
        : '');

    return (
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <PropertyListItem
            customStyle={propertyListItemStyle}
            label='CLIENTS:DETAIL:POSTAL_ADDRESS:CITY'
            value={companyPostalAddress.cityName}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <PropertyListItem
            customStyle={propertyListItemStyle}
            label='CLIENTS:DETAIL:POSTAL_ADDRESS:STATE'
            value={companyPostalAddress.stateName}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <PropertyListItem
            customStyle={propertyListItemStyle}
            label='CLIENTS:DETAIL:POSTAL_ADDRESS:STREET'
            value={companyPostalAddress.street}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <PropertyListItem
            customStyle={propertyListItemStyle}
            label='CLIENTS:DETAIL:POSTAL_ADDRESS:NUMBER'
            value={companyPostalAddress.number}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <PropertyListItem
            customStyle={propertyListItemStyle}
            label='CLIENTS:DETAIL:POSTAL_ADDRESS:FLOOR_AND_APARMENT'
            value={floorAndAparment}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <PropertyListItem
            customStyle={propertyListItemStyle}
            label='CLIENTS:DETAIL:POSTAL_ADDRESS:POSTAL_CODE'
            value={companyPostalAddress.postalCode}
          />
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default PostalAddressDetail;
