const styles = {
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.5rem',
    padding: '1.5rem 1rem',
    borderRadius: '1rem',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: (responsive) => ({
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    width: '100%',
    gap: '0.5rem',
  }),
  firstLine: (responsive) => ({
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'space-between',
    gap: '0.5rem',
    width: '100%',
  }),
  inputsContainer: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    width: '100%',
    flexDirection: responsive ? 'column' : 'row',
  }),
  actionsContainer: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    height: '49px',
    justifyContent: 'flex-end',
    maxWidth: !responsive && '220px',
    width: '100%',
  }),
  clearButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      fontSize: '0.75rem',
      padding: '1rem 0.5rem',
    },
  },
  searchButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      minWidth: '46px',
      '& > .MuiButton-startIcon': {
        margin: '0',
      },
    },
  },
};

export default styles;
