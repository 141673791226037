import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { Alert, Button, Input, StrongModalText } from '@atoms';
import { usePartnerCustomers } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { SelectFetch } from '@molecules';
import { zodResolver } from '@hookform/resolvers/zod';
import { FIELDS } from './constants';
import useCreateUser from './data/UserRepository/hooks/useCreateUser';
import userManagementFormSchema, {
  userManagementFormSchemaType,
} from './UserManagementForm.schema';
interface Company {
  id: number;
  legalName: string;
  legalIdentification: string;
  fkCustomerGroup: number | null;
}

/**
 * Create user screen
 */
const CreateUserFormScreen: FC = () => {
  const { t } = useTranslation();
  const customers = usePartnerCustomers();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm<userManagementFormSchemaType>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      customers: [],
    },
    resolver: zodResolver(userManagementFormSchema),
    mode: 'onChange',
  });
  const {
    isLoading,
    isSuccess,
    isError,
    alertMessage,
    setIsSuccess,
    setIsError,
    isModalOpen,
    setIsModalOpen,
    createUser,
  } = useCreateUser(reset);
  /**
   Parses company options into an object with text and value fields.
   * @param item - company object to parse 
   */
  const parseOption = (item: Company) => ({ text: item?.legalName, value: item?.id?.toString() });
  /**
   * Get an array of customer id´s and returns an array of their names
   * @param customersId - array of string id
   */
  const getCustomersName = (customersId: userManagementFormSchemaType['customers']) => {
    return customersId.map(
      (item) => customers.data?.find((customer) => customer.id === Number(item))?.legalName
    );
  };
  const disableButton = !isValid;
  return (
    <MainMenuLayout>
      <FullWidthContent>
        <BoxContainer>
          <Typography variant='h4'>{t('MENU:USERS_MANAGEMENT:CREATE')}</Typography>
          <Box>
            <Grid container spacing={3}>
              {FIELDS.map(({ name, label, type, getOptions }) => {
                return (
                  <Grid key={name} item xs={12} md={12}>
                    <Controller
                      name={name as keyof userManagementFormSchemaType}
                      control={control}
                      render={({ field }) => {
                        return type === 'autocomplete' ? (
                          <SelectFetch
                            {...field}
                            placeholder={label}
                            parseOption={parseOption}
                            getOptions={getOptions}
                            multiple
                          />
                        ) : (
                          <Input
                            {...field}
                            label={label}
                            variant='outlined'
                            size='small'
                            fullWidth
                            error={Boolean(errors[name as keyof userManagementFormSchemaType])}
                            helperText={errors[name as keyof userManagementFormSchemaType]?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Alert
            modalVisible={isError}
            handleClose={() => {
              setIsError(false);
            }}
            variant='error'
            title={alertMessage.title}
            description={alertMessage.description}
          />
          <Alert
            modalVisible={isSuccess}
            handleClose={() => setIsSuccess(false)}
            variant='success'
            title={alertMessage.title}
            description={alertMessage.description}
          />
          <Alert
            variant='warning'
            modalVisible={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleSubmit={handleSubmit(createUser)}
            closeButtonText='COMMON:CANCEL'
            title={t('PROSPECTS:SEND_EMAIL:MODAL:TITLE')}
            submitLoading={isLoading}
            content={
              <Trans i18nKey='USERS_MANAGEMENT:CONFIRMATION_MODAL:DESCRIPTION'>
                {'Estás a punto de crear el pedido de alta de '}
                <StrongModalText>
                  {{ name: `${getValues('firstName')} ${getValues('lastName')}` }}
                </StrongModalText>
                {'con el usuario '}
                <StrongModalText>{{ email: getValues('email') }}</StrongModalText>
                {'para gestionar la/las razon/es sociales '}
                <StrongModalText>
                  {{ customerId: getCustomersName(getValues('customers')) }}
                </StrongModalText>
                {'¿Estás de acuerdo?'}
              </Trans>
            }
          />
        </BoxContainer>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            isLoading={isLoading}
            disabled={disableButton}
            onClick={() => setIsModalOpen(true)}
            text={t('COMMON:ADD')}
          />
        </Box>
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default CreateUserFormScreen;
