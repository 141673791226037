import { OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import useRoles from './useRoles';
import useSelectedCompanyCategories from './useSelectedCompanyCategories';
import useSelectedCompanyProducts from './useSelectedCompanyProducts';

/**
 * @function useShowMenuByCategoriesAndProduct
 * @description This function is used to show the menu based on the corporate product or categories is generic or more than one category.
 * @return {boolean} - Returns a boolean value indicating whether the menu should be shown or not.
 * @example
 * const showMenu = useShowMenuByCategoriesAndProduct();
 */
const useShowMenuByCategoriesAndProduct = (): boolean => {
  const { validateRoles } = useRoles();
  const { data } = useSelectedCompanyProducts();
  const { data: categories } = useSelectedCompanyCategories();
  const isOperationRole = validateRoles([OPERATIONS, OPERATIONS_SUPERVISOR]);

  if (isOperationRole) {
    return true;
  }

  const isGeneric = categories.length === 1 && categories[0] === 'GENERIC';

  return (
    data.includes('Corporativo') || data.includes('Almuerzo') || isGeneric || categories.length > 1
  );
};

export default useShowMenuByCategoriesAndProduct;
