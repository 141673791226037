import useSWR from 'swr';

/**
 * SWR hook to fetch consumption by commerce
 */
const useConsumptionByCommerce = (companyId: string, params: Record<string, string>) => {
  const { data, isLoading, error, mutate } = useSWR(
    () => {
      const query = new URLSearchParams({ ...params, companyId }).toString();
      return params?.product ? `/client/consumption-by-commerce?${query}` : false;
    },
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error, mutate };
};

export default useConsumptionByCommerce;
