import { z } from 'zod';

const onlyLettersRegex = /^[a-zA-Z\s]+$/;
const userManagementFormSchema = z.object({
  firstName: z
    .string()
    .min(1, 'COMMON:VALIDATIONS:REQUIRED_FIELD')
    .max(50, 'USERS_MANAGEMENT:ERROR:INPUT_MAX_LENGTH')
    .regex(onlyLettersRegex, { message: 'COMMON:VALIDATIONS:INVALID_NAME' }),
  lastName: z
    .string()
    .min(1, 'COMMON:VALIDATIONS:REQUIRED_FIELD')
    .max(50, 'USERS_MANAGEMENT:ERROR:INPUT_MAX_LENGTH')
    .regex(onlyLettersRegex, { message: 'COMMON:VALIDATIONS:INVALID_LASTNAME' }),
  email: z
    .string()
    .min(1, 'COMMON:VALIDATIONS:REQUIRED_FIELD')
    .max(255, 'USERS_MANAGEMENT:ERROR:EMAIL_MAX_LENGTH')
    .email({ message: 'COMMON:VALIDATIONS:INVALID_EMAIL' }),
  customers: z.array(z.string()).nonempty(),
});

export type userManagementFormSchemaType = z.infer<typeof userManagementFormSchema>;

export default userManagementFormSchema;
